import React, {useState, useRef, useEffect} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";

// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import d2a from "assets/img/d2a.png";
import d2g from "assets/img/d2g_logo.png";
import goldImg from "assets/img/gold.png";

import FormControl from "@mui/material/FormControl";

import styles from "assets/jss/material-kit-react/views/landingPage.js";
import TwitchEmbedVideo from 'react-twitch-embed-video';
// Sections for this page
import NavPills from "../../components/NavPills/NavPills";
import CustomInput from "../../components/CustomInput/CustomInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/Search";
import config from "../../config/config.json";
import axios from "axios";
import toast from "react-hot-toast";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from "../../components/CustomButtons/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ComingSoon from "../../assets/img/coming_soon.png";
import cubeImg from "../../assets/img/horadric_cube.png";
import d2rsIcon from "../../assets/img/d2rsicon.png";
import d2rshopIcon from "../../assets/img/d2rshopicon.png";
import scrollImg from "../../assets/img/bark_scroll.png";
import tzImg from "../../assets/img/tz.png";
import skillImg from "../../assets/img/book_of_skill.png";
import playerCharacterImg from "../../assets/img/player_character.png";
import searchImg from "../../assets/img/search.png";
import broadcastImg from "../../assets/img/friendlist_broadcasticon.png";
import breakpointsImg from "../../assets/img/breakpoints.png";
import amaImg from "../../assets/img/ama.png";
import assaImg from "../../assets/img/assa.png";
import necImg from "../../assets/img/nec.png";
import barbImg from "../../assets/img/barb.png";
import palaImg from "../../assets/img/pala.png";
import sosoImg from "../../assets/img/soso.png";
import duduImg from "../../assets/img/dudu.png";
import Footer from "../../components/Footer/Footer";
import chestImg from "assets/img/d2rchest.png";
import pvpImg from "assets/img/pvp.png";
import namelockVid from "assets/vid/Namelock.mp4";
import namelockStompVid from "assets/vid/Namelock_Stomp.mp4";
import namelockChainsVid from "assets/vid/Namelock_to_Teleport_Chains.mp4";
import throwlockVid from "assets/vid/Throwlock_locking_with_Blessed_Hammer_on_left_side.mp4";
import ctVid from "assets/vid/ct_teleport.mp4";
import smiteVid from "assets/vid/Smite_Cast_Combo.mp4";
import telelocksVid from "assets/vid/Telelocks.mp4";
import fohVid from "assets/vid/FoH_Combo.mp4";
import { useLocation  } from "react-router-dom";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function PublicSearch(props) {
    const classes = useStyles();
    const {...rest} = props;
    const sessionToken = localStorage.getItem("session_token")
    const [minVal, setMinVal] = useState(1);
    const [maxVal, setMaxVal] = useState(999);

    //get saved searches from this user and add them to suggestions
    const [baseSuggestions] = useState([
        {title: "Paladin Skills", search_term: ",paladin skill or paladin only"},
        {title: "Sorceress Skills", search_term: ",sorceress Skill or sorceress only"},
        {title: "Necromancer Skills", search_term: ",necromancer Skill or necromancer only"},
        {title: "Assassin Skills", search_term: ",assassin Skill or assassin only"},
        {title: "faster cast rate", search_term: ",faster cast=1-20"},
        {title: "life", search_term: ",life=1-999"},
        {title: "Strength or Dexterity", search_term: ",str=1-99 or dex=1-99"},
        {title: "all resist", search_term: ",all resist"},
        {title: "fire resist", search_term: ",fire resist"},
        {title: "cold resist", search_term: ",cold resist"},
        {title: "lightning resist", search_term: ",lightning resist"},
        {title: "Life Leech", search_term: ",life stolen"},
        {title: "Mana Leech", search_term: ",mana stolen"},
        {title: "Maximum Damage", search_term: ",max"},
        {title: "Attack Rating", search_term: ",attack rat"},
        {title: "Increased Attack Speed", search_term: ",increased attack"},
        {title: "Minimum 2 Sockets", search_term: ",socketed=2"},
        {title: "Unidentified", search_term: ",unid"},
        {title: "Ethereal", search_term: ",eth"},
        {title: "Replenishes/Repairs", search_term: ",replenishes or repairs"},
        {title: "LLD 9", search_term: ",required level=1-9"},
        {title: "LLD 30", search_term: ",required level=1-30"},
        {title: "MLD 49", search_term: ",required level=1-49"},
    ]);
    const [baseRarities] = useState([
        {title: "Rare/Craft", search_term: ",rare"},
        {title: "Magic", search_term: ",magic"},
        {title: "Unique", search_term: ",unique"},
        {title: "Set", search_term: ",set"},
        {title: "White/Bases", search_term: ",white"},
        {title: "Runeword", search_term: ",runeword"},
        {title: "Rune", search_term: ",rune"},
    ]);
    const location = useLocation();

    // Extract query parameter from URL
    const queryParams = new URLSearchParams(window.location.search);
    const qterm = queryParams.get('term') || ""; // Default to empty string if 'term' is not present

    // Use the extracted value as the default for baseSearchTerm
    const [baseSearchTerm] = useState(qterm);
    const [searchTerm, setSearchTerm] = useState(baseSearchTerm);
    const [oldCheck, setOldCheck] = useState(searchTerm);
    React.useEffect(() => {
            if (oldCheck !== searchTerm) {
                setOldCheck(searchTerm)
                if (!isTyping) {
                    getItemsSearch()
                }
            }
        }
    )

    const [isTyping, setIsTyping] = useState(false)
    let sss = localStorage.getItem("showSavedSearches")
    if (!sss) {
        sss = 0
    }
    let sr = localStorage.getItem("showRarities")
    if (!sr) {
        sr = 0
    }
    let sc = localStorage.getItem("showCategories")
    if (!sc) {
        sc = 0
    }
    let ss = localStorage.getItem("showStats")
    if (!ss) {
        ss = 0
    }
    const [tourDiscords] = useState([
        {
            name: "d2g",
            image: d2g,
            discord_invite: "https://discord.gg/xwtATmyEkN",
            tours: [
                {
                    name: "Necro vs Druid (NvD/DvN)",
                    ruleset: "standard",
                    time: "18 cet",
                    day: "Wednsday or Thursday",
                    participants: "",
                    participants_current: "8",
                    participants_max: "16",
                    prize: "???",
                },
                {
                    name: "1v1 D²G Diggi tour",
                    ruleset: "standard",
                    time: "18 cet",
                    day: "Friday",
                    participants: "",
                    participants_current: "8",
                    participants_max: "16",
                    prize: "???",
                },
                {
                    name: "Team vs Team",
                    ruleset: "tvt",
                    time: "18 cet",
                    day: "Saturday",
                    participants: "",
                    participants_current: "8",
                    participants_max: "16",
                    prize: "???",
                }
            ],
            streams: ["jayroxor"],
            ruleSets: [
                {
                    name: "standard",
                    content: <span style={{
                        display: 'block',
                        padding: '20px',
                        backgroundColor: '#f0f0f0',
                        border: '1px solid #ccc',
                        borderRadius: '5px'
                    }}>
      <div className="container">
        <h2 style={{color: '#142d4c'}}>Signing up as Tour Participant</h2>
        <p>
          <a href="https://discord.gg/xwtATmyEkN"
             style={{color: '#142d4c', textDecoration: 'none'}}
             rel="noreferrer"
             target="_blank">
            <button style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '10px 20px',
                border: 'none',
                backgroundColor: '#142d4c',
                color: 'white',
                fontSize: '16px',
                width: '100%',
                borderRadius: '5px',
                cursor: 'pointer'
            }}>
              Join the D2G Discord to participate!
            </button>
          </a>
        </p>
        <p>Participants must be a member of the D²G discord server.</p>
        <p>Once the tour registration is closed, the character class you have signed up with is fixed and final. Also, if you are playing a class that has multiple builds, please PM your build variant to one of the admins/mods available and online before the brackets are rolled. We will also announce the closing of the sign-up and the time to post your builds before rolling the bracket!</p>
        <p>Do NOT deafen yourself in the voice channel (you can deafen other people than mods of course if you feel disturbed - a tour mod has to reach you via voice! If they cannot, your duel will result in a round or even duel loss).</p>
        <p>You cannot participate in tournaments if you blocked or muted a member of the tour-crew.</p>

        <div className="rules-list">
          <h3>Start & Ending of a Duel</h3>
          <ul>
            <li>Players/teams must be set hostile. The player joining the game last is responsible for setting the opposing player(s) hostile. Rounds where one or more players are not set hostile will be redone. If this happens multiple times the responsible player/team will receive a round loss on consequent rounds</li>
            <li>Both players signal their readiness by posting {"'"}g{"'"} or {"'"}go{"'"} in the chat. It only stands for 5 seconds and has to be signaled again after that period. No offensive abilities or curses should be casted prior to the start of the duel</li>
            <li>The duel can be interrupted by typing {"'"}s{"'"} or {"'"}sec{"'"} (To call a Mediator)</li>
            <li>Picking Mana is not a reason to interrupt a duel. The round has not to be paused when a player calls {"'"}m{"'"} or {"'"}mana{"'"} unless all mana pools are depleted. The duel will continue once the mana pool has been replenished by a helper and both players signal their readiness by posting {"'"}g{"'"} or {"'"}go{"'"} in the chat</li>
            <li>You are not allowed to use any skill/ability while waiting for mana!</li>
            <li>Excessive Use of Interruption-Calls will result in a loss round and may also end in a disqualification</li>
            <li>Chatting within an active round is not allowed. (Except causing an interruption, see above) There will be a one-time warning followed by round loss or even a DQ</li>
            <li>Ties are null duels</li>
            <li>A disconnection will count as a loss unless both players agree to redo the round</li>
          </ul>

          <h3>Area/Map</h3>
          <ul>
            <li>Blood Moor, Bridge Map, fully cleared. Square without Boxes preferred (H Pattern)</li>
            <li>Item spacing must be turned off when creating games</li>
          </ul>

          <h3>Map Objective Restriction</h3>
          <ul>
            <li>Excessive Camping around/in houses/boxes or entering the Den will result in actions against the abusive player</li>
            <li>Taking shrines is forbidden. If a shrine causing an advantage for the player is taken either consciously or accidentally, the round will count as a loss (Removing a curse is considered an advantage. Any shrine except XP, mana regen, and stamina shrine is considered advantageous)</li>
          </ul>

          <h3>Mana Pooling</h3>
          <ul>
            <li>Mana may be pooled all over the map</li>
            <li>You cannot purposefully camp mana or drink mana to deplete the pool</li>
          </ul>

          <h3>Showcasing & Character Name</h3>
          <ul>
            <li>Make sure to show your life tooltip numbers above the life bubble</li>
            <li>Every player or team has the right to request the showcase of a player{"'"}s current setup and stats. Opening the stash will result in a loss of all previous rounds; a refusal will result in a disqualification</li>
            <li>Using a build or itemization against the Ruleset will also result in a disqualification</li>
            <li>Character names must not be shorter than 2 Latin letters</li>
          </ul>

          <h3>D2R/Blizzard Terms of Service</h3>
          <ul>
            <li>Any violation against the Game{"'"}s Terms of Service will result in a disqualification</li>
          </ul>

          <h3>Precast</h3>
          <ul>
            <li>Only from Cube (inventory) is allowed</li>
          </ul>

          <h3>Allowed Classes & Builds / Token Usage</h3>
          <ul>
            <li>You may enter the tournament with one of the following Builds/Class: Wind Druid, Assassin, Barb, Necro, Paladin (Smiter variants or Hammerdin), Sorceress, Javazon & Bowazon</li>
            <li>Using Respec-Tokens is not allowed during an active tournament or duel unless specifically stated</li>
          </ul>

          <h3>Gameplay & Behavior / Priding</h3>
          <ul>
            <li>Toxic attitude and arguing with the host/moderator or ignoring them will lead to disqualification or even a ban</li>
            <li>Excessive off-screening is prohibited. This includes random ability spamming (Builds with access to Teleport Skill)</li>
            <li>Defensive gameplay is accepted to an extent; refusal to attack an opponent is not. {"'"}Catch me if you can{"'"} playstyle will result in a round loss</li>
            <li>Avoiding contact with an opponent intentionally with no plans of direct attacking is considered priding and will result in a round loss</li>
            <li>If you get to a low life, continue dueling to the finish. Running to replenish life will lead to Loss/DQ</li>
            <li>No camping in houses, near walls, corners, puddles, or any other map objectives</li>
            <li>Share your screen on the D²G discord server before your match has started, no exceptions or excuses! If you cannot screen share without issues, do NOT join the tournament! If you break this rule: warning -{'>'} round loss -{'>'} DQ</li>
            <li>Stream sniping is prohibited</li>
            <li>Switch Casting as shown in Cooley{"'"}s YT video is prohibited to use and will lead to an instant DQ</li>
          </ul>

          <h3>Ingame Video Resolution</h3>
          <ul>
            <li>You are not allowed to use any resolution with an aspect ratio higher than 16:9. Breaking this rule will lead to disqualification!</li>
          </ul>

            <h3>Item Bans & Restrictions</h3>
<ul>
  <li>
    <strong>Runewords</strong>
    <ul>
      <li>Plague, Lawbringer</li>
      <li>Any runeword granting a Damage dealing aura</li>
      <li>Phoenix Runeword (Shield) is globally banned against Fire-Sorceress</li>
    </ul>
  </li>
  <li>
    <strong>Magical / Rare / Uniques</strong>
    <ul>
      <li>Rising Sun, Blackoak Luna, The Reaper{"'"}s Toll, Executioner{"'"}s Justice are banned</li>
      <li>Sunder Grand Charms are banned</li>
      <li>You may wear one item that grants {"'"}Chance to cast{"'"} Lifetap</li>
      <li>You are allowed to use one Poison and one of each Elemental damage type Small Charm at the same time</li>
    </ul>
  </li>
  <li>
    <strong>Consumable Items</strong>
    <ul>
      <li>No other than Mana Potions, Bolts, Arrows are allowed; this includes the use of Throwing Potions</li>
      <li>Rejuv & Healing Potions may only be used against Poison Damage (necro & poison bow amazon)</li>
      <li>Gas Potions are prohibited</li>
      <li>Antidote, Stamina & Thawing Potions are prohibited</li>
    </ul>
  </li>
</ul>
            <h3>Abilities & Spells / Skill Restrictions</h3>
<ul>
  <li>Globally Banned Skills: Mind Blast, Bone Prison, Holy Freeze, Decrepify, and Attract</li>
  <li>
    <strong>Assassin</strong>
    <ul>
      <li>No restrictions</li>
    </ul>
  </li>
  <li>
    <strong>Barbarian</strong>
    <ul>
      <li>Leap is banned</li>
      <li>Banned against Warcry: Doom runeword, Last Wish runeword</li>
    </ul>
  </li>
  <li>
    <strong>Necromancer</strong>
    <ul>
      <li>Bone Spirit has to be casted within 1.5 screens of the opponent and Bone Wall is not allowed to be casted directly on an opponent, casted walls must have a 1 screen distance from an opponent</li>
      <li>Poison Nova & Bone Spirit/Teeth hybrid builds are not allowed. One or the other skill set has to be dominant with maxed synergies</li>
    </ul>
  </li>
  <li>
    <strong>Druid</strong>
    <ul>
      <li>Recasting Cyclone armor more than once per 10 seconds is not allowed</li>
      <li>Twister and Tornado hybrids are not allowed</li>
      <li>Life Replenish from gear is limited to 20</li>
    </ul>
  </li>
  <li>
    <strong>Paladin</strong>
    <ul>
      <li>Auradin is banned</li>
      <li>Smite has a life tap of one cast per duel. Life tab may not be recast once overwritten</li>
    </ul>
  </li>
  <li>
    <strong>Amazon</strong>
    <ul>
      <li>Banned Skills: Decoy</li>
      <li>Poison Javelin Skill Tree Hybrids are not allowed, the build must be focused on either Poison or Lightning</li>
      <li>Valkyrie is banned in Bow vs. Bow duels</li>
    </ul>
  </li>
  <li>
    <strong>Sorceress</strong>
    <ul>
      <li>Energy Shield</li>
      <li>ES has to be maxed as well as its synergy Telekinesis</li>
      <li>ES has to be active the entire duel and must not be turned off to pre-buff other spells</li>
      <li>Single Skill focused builds are not allowed. You must use a combination of at least 2 Skills</li>
    </ul>
  </li>
</ul>

        </div>
      </div>
    </span>
                }
            ],
            guides: [
                {
                    name: "General PvP Guide",
                    nameImage: pvpImg,
                    ruleset: "all",
                    date: "",
                    className: "all",
                    difficulty: "",
                    sections: [
                        {
                            name: "Namelock",
                            content: (
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', textAlign: 'center' }}>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                        textAlign: 'center'
                                    }}>
                                        <p>Namelock</p>
                                        <ul style={{listStyleType: 'circle', textAlign: 'left'}}>
                                            <li>Place cursor over your
                                                opposition
                                            </li>
                                            <li>Click attack with your
                                                mouse and hold it down
                                            </li>
                                            <li>It is best practice to
                                                get used to centering your cursor after grabbing your lock!
                                            </li>
                                        </ul>
                                    </div>
                                    {/* eslint-disable-next-line react/no-unknown-property */}
                                    <video style={{maxWidth: '100%'}} controls>
                                        <source src={namelockVid} type="video/mp4"/>
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            ),
                        },
                        {
                            name: "Namelock Stomp",
                            content: (
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', textAlign: 'center' }}>
                                    <p>Namelock Stomp</p>
                                    <ul style={{ listStyleType: 'circle', textAlign: 'left' }}>
                                        <li>Place cursor over your opposition</li>
                                        <li>Click attack with your mouse and hold it down</li>
                                        <li>It is best practice to get used to centering your cursor after grabbing your lock!</li>
                                        <li>Change to Teleport whilst holding your lock</li>
                                    </ul>
                                    {/* eslint-disable-next-line react/no-unknown-property */}
                                    <video style={{ maxWidth: '100%' }} controls>
                                        <source src={namelockStompVid} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            ),
                        },
                        {
                            name: "Namelock to Teleport Chains",
                            content: (
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', textAlign: 'center' }}>
                                    <p>Namelock to Teleport Chains</p>
                                    <ul style={{ listStyleType: 'circle', textAlign: 'left' }}>
                                        <li>Place cursor over your opposition</li>
                                        <li>Click attack with your mouse and hold it down</li>
                                        <li>It is best practice to get used to centering your cursor after grabbing your lock!</li>
                                        <li>Change to Teleport whilst holding your lock</li>
                                        <li>Continue to hold down Teleport with your cursor in the centre of the screen (preferably at your character{"'"}s feet)</li>
                                        <li>Beginners: Hold down Teleport with your cursor in the centre</li>
                                        <li>Pro: Re-click on each Teleport to keep your lock consistently, allowing more control overall</li>
                                    </ul>
                                    {/* eslint-disable-next-line react/no-unknown-property */}
                                    <video style={{ maxWidth: '100%' }} controls>
                                        <source src={namelockChainsVid} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            ),
                        },
                        {
                            name: "Throwlock",
                            content: (
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', textAlign: 'center' }}>
                                    <p>Throwlock</p>
                                    <p>It is worth noting that not all skills, such as Blessed Hammer, allow you to switch to Teleport in order to stomp your opposition. A good alternative is to switch to Throw, allowing you to Teleport onto your victim accordingly. Locking players with Throw is a common habit, cutting out animation time that would occur with other skills requiring full casting.</p>
                                    <p>This is also a good time to get used to switching from skills on the left side to the right when attacking.</p>
                                    <ul style={{ listStyleType: 'circle', textAlign: 'left' }}>
                                        <li>Place cursor over your opposition</li>
                                        <li>Click left attack with your mouse and hold it down</li>
                                        <li>It is best practice to get used to centering your cursor after grabbing your lock!</li>
                                        <li>Change to Throw while your hammer is casting and you are holding your lock</li>
                                        <li>Change to Teleport while holding your lock</li>
                                        <li>Make your next move (chains/attacks are always a good idea!)</li>
                                    </ul>
                                    {/* eslint-disable-next-line react/no-unknown-property */}
                                    <video style={{ maxWidth: '100%' }} controls>
                                        <source src={throwlockVid} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            ),
                        },
                        {
                            name: "Telelocks",
                            content: (
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', textAlign: 'center' }}>
                                    <p>Telelocks</p>
                                    <ul style={{ listStyleType: 'circle', textAlign: 'left' }}>
                                        <li>Place cursor over your opposition whilst having teleport ready to use</li>
                                        <li>Click attack with your mouse to action the teleport</li>
                                        <li>Center your mouse to your feet before the Teleport lands</li>
                                        <li>This allows you to take action once the stomp has finished</li>
                                        <li>Make your next move (chains/attacks are always a good idea!)</li>
                                    </ul>
                                    {/* eslint-disable-next-line react/no-unknown-property */}
                                    <video style={{ maxWidth: '100%' }} controls>
                                        <source src={telelocksVid} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            ),
                        },
                        {
                            name: "C/T Teleport",
                            content: (
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', textAlign: 'center' }}>
                                    <p>C/T Teleport</p>
                                    <p>The C/T teleport method allows for greater teleportation distances than usual. Advantages include quicker off-screen escapes, rapid pursuit of targets, and the ability to stomp stationary players who cannot see you on their screen.</p>
                                    <p>Adjusting your screen borders via calibration can provide better visibility of your target before teleporting. Alternatively, you can use the map to line up your teleports once you are familiar with the required distance.</p>
                                    <ul style={{ listStyleType: 'circle', textAlign: 'left' }}>
                                        <li>Open the character screen to teleport left or the skill screen to teleport right.</li>
                                        <li>Move your cursor as far to the edge as possible (or over your target if they are visible at the border).</li>
                                        <li>Teleport! (Note: deadspace can occur, preventing teleportation).</li>
                                    </ul>
                                    {/* eslint-disable-next-line react/no-unknown-property */}
                                    <video style={{ maxWidth: '100%' }} controls>
                                        <source src={ctVid} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            ),
                        },
                        {
                            name: "Smite Combo",
                            content: (
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', textAlign: 'center' }}>
                                    <p>Smite Combo</p>
                                    <p>Like chainlocking for left-click attackers, utilizing the Smite Combo is an effective way of controlling the duel by maintaining high pressure and increasing your chances of damaging your opponent. For Smiters, this maneuver integrates the swirl effect with Smite, paving the way for a FoH combo.</p>
                                    <p>Always ensure to keep your mouse centered, allowing you to re-lock/cast with your cursor maintaining your opposition{"'"}s character in sight.</p>
                                    <ul style={{ listStyleType: 'circle', textAlign: 'left' }}>
                                        <li>Lock your opponent with left click (Smite, for example) - Lock/Telestomp to mouse center are effective methods of initiating Smite Combo</li>
                                        <li>While the Smite animation is in motion, activate Teleport on the Right Skill-Set</li>
                                        <li>Right-click to cast Teleport</li>
                                        <li>Smite {">"} Repeat.</li>
                                    </ul>
                                    {/* eslint-disable-next-line react/no-unknown-property */}
                                    <video style={{ maxWidth: '100%' }} controls>
                                        <source src={smiteVid} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            ),
                        },
                        {
                            name: "FoH Combo",
                            content: (
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', textAlign: 'center' }}>
                                    <p>FoH Combo</p>
                                    <p>Once a Smiter has applied their swirl effect upon smiting their opposition, they can proceed to use Fist of Heavens (FoH) to interrupt their casting. Implementing this combo allows consistent damage application while maintaining control of the duel.</p>
                                    <p>FoH can be casted from either the left or right skill-set, altering the order in which you click your mouse accordingly.</p>
                                    <ul style={{ listStyleType: 'circle', textAlign: 'left' }}>
                                        <li>Lock your opposition with Smite, casting the attack</li>
                                        <li>Cast Fist of Heavens on your opposition (always keeping your mouse center)</li>
                                        <li>This will interrupt your opposition{"'"}s casting</li>
                                        <li>If you FoH on the left, you do not need to reclick on your opposition</li>
                                        <li>If you FoH on the right, you will need to reclick after your Smite, using your right skill cast attack</li>
                                        <li>Hold the lock when casting FoH</li>
                                        <li>Using an aura like Holy Freeze or Conviction is optional</li>
                                        <li>Cast Teleport while holding FoH lock to stomp your opposition</li>
                                        <li>Repeat starting from Smite</li>
                                    </ul>
                                    {/* eslint-disable-next-line react/no-unknown-property */}
                                    <video style={{ maxWidth: '100%' }} controls>
                                        <source src={fohVid} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            ),
                        },
                    ],
                },
                {
                    name: "Amazon",
                    nameImage: amaImg,
                    ruleset: "all",
                    date: "",
                    className: "all",
                    difficulty: "",
                    sections: [
                        {
                            name: "Java",
                            content: (
                                <span>
    <h1>Java Amazon Guide</h1>
    <p>This guide is intended to provide a basic overview of how to gear up and play your Javazon in PvP encounters. There are various options and adjustments based on your preferences and playstyle. The way you kit your Javazon will depend on how high you whether you play with 68%/99% FCR, based on your 1v1 requirements. The Javazon is a versatile class in PvP with its range of skills and abilities.</p>
    <h2>Stats &amp; Skills</h2>
    <p><strong>Stats</strong></p>
    <p>Strength: As much as you need to wear your gear</p>
    <p>Dexterity: Enough for max block with block setup, rest into Vitality</p>
    <p>Vitality: All remaining points</p>
    <p><strong>Skills</strong></p>
    <p>1v1</p>
    <p>1 Jab, 1 Impale</p>
    <p>1 Poison Javelin, 1 Plague Javelin, 1 Lightning Bolt, 1 Charged Strike, 1 Lightning Strike</p>
    <p>20 Power Strike, 20 Charged Strike, 20 Lightning Strike, 20 Lightning Bolt</p>
    <p>1 Power Strike, 1 Fend</p>
    <p>1 Inner Sight, 1 Slow Missiles</p>
    <p>1 Dodge, 1 Avoid, 1 Evade</p>
    <p>1 Valkyrie</p>
    <p>1 Pierce, 1 Critical Strike, 1 Penetrate</p>
    <p>Rest into Lightning Fury</p>
    <p>For most PvP Javazons, aiming for the following breakpoints is practical and effective:</p>
    <p>FCR: 68%-99% (depending on setup)</p>
    <p>FHR: 48%-86% (depending on preference)</p>
    <h2>Builds</h2>
    <p><strong>FCR Javazon vs Caster</strong></p>
    <p>Armor: Enigma</p>
    <p>Helm: Griffons (Socket is optional: RBF, Jah, IAS Jewel etc)</p>
    <p>Shield: Spirit Monarch</p>
    <p>Weapon/Shield: Thunderstroke OR 5-6 Java Skills/40 IAS Javas</p>
    <p>Gloves: 2/20IAS/High Str/High Dex/Knockback</p>
    <p>Belt: Arach</p>
    <p>Amu: 2/20/Life Amu (added stats such as res/rep life/mana are a bonus)</p>
    <p>Rings: 1 BK &amp; Natures Peace</p>
    <p>Boots: SandTreks</p>
    <h3>Inventory</h3>
    <p>Life Lightning Skillers</p>
    <p>Life/Res or Life/Mana SC depending on requirements</p>
    <h3>Match Up Swaps</h3>
    <p>VS Light: Wisp &amp; Lo Griffon</p>
    <p>Max Block: Stormshield, Shadows, FCR/High Str/High Dex Ring, 2/20IAS/Dex/KB Gloves, Ravenfrost, cats eye amulet - All options</p>
    <p>DR: Verdungo, 2/30/20/2s (2xBer) circ</p>
    <h2>Skill Usage</h2>
    <p>The Javazon is highly dependent on Lightning Fury and Charged Strike, utilizing Lightning Strike for single target damage and Valkyrie for additional tankiness.</p>
    <h2>Main Attacks:</h2>
    <p>Main damage sources are Lightning Fury and Charged Strike</p>
</span>
                            ),
                        },
                        {
                            name: "Bow",
                            content: (
                                <span>
    <h1>Bow Amazon Guide</h1>
    <p>This Guide is intended to give a basic overview on how to generally gear up and play your Bowazon vs most classes. There are various other options you can utilize, depending on your budget, playstyle, and personal preferences. Keep in mind that Bow Amazons will never be a cheap character, so get ready to spend at least a couple thousand fg.</p>
    <h2>Stats &amp; Skills</h2>
    <p><strong>Skill Tree:</strong></p>
    <p><strong>Javelin Skills:</strong></p>
    <ul>
        <li>1 Jab</li>
        <li>1 Impale</li>
        <li>1 Poison Javelin</li>
        <li>1 Plague Javelin</li>
        <li>1 Lightning Fury</li>
    </ul>
    <p><strong>Passive Skills:</strong></p>
    <ul>
        <li>Start by putting 1 in every skill</li>
        <li>When fully geared in basic TvT setup and with BO, you should have:</li>
        <li>56% Dodge (20 lvl)</li>
        <li>66% Avoid (21 lvl)</li>
        <li>56% Evade (20 lvl)</li>
        <li>72% Critical Strike (27 lvl)</li>
        <li>Penetrate - all the leftover points go here</li>
    </ul>
    <p><strong>Bow Skills:</strong></p>
    <ul>
        <li>1 Magic Arrow</li>
        <li>1 Cold Arrow (btw did you guys know that if you run out of arrows, you can still shoot Magic Arrows, ez)</li>
        <li>Max Multiple Shot</li>
        <li>Max Guided Arrow</li>
        <li>1 Strafe</li>
    </ul>
    <p><strong>Stats:</strong></p>
    <p>Strength: As much as you need to wear your gear</p>
    <p>Dexterity: As much as you need to get block</p>
    <p>Vitality: All remaining points</p>
    <p>To make sure you get the correct IAS breakpoints, use the IAS Calculator.</p>
    <h2>Gearing</h2>
    <h3>Inventory:</h3>
    <ul>
        <li>1x Poison SC</li>
        <li>15x Max Damage/AR/FRW SCs</li>
        <li>4x Max Damage/AR/FHR SCs (this can be skipped sometimes)</li>
        <li>13x Max Damage/AR/Life SCs</li>
    </ul>
    <p>Cube in 1v1</p>
    <ul>
        <li>4 additional Max Damage/AR/Life SCs in TvT</li>
    </ul>
    <h3>Cube:</h3>
    <ul>
        <li>CTA</li>
        <li>2 SoJ</li>
        <li>Mara{"'"}s</li>
        <li>Demon Limb</li>
    </ul>
    <h2>Builds</h2>
    <p><strong>Basic ZvAll:</strong></p>
    <ul>
        <li>Faith GMB</li>
        <li>120/45/30 FRW Hat</li>
        <li>Fortitude</li>
        <li>Highlord{"'"}s Wrath</li>
        <li>Nosferatu{"'"}s Coil</li>
        <li>Gore Rider Boots</li>
        <li>20 IAS + KB Gloves with as much +Passive skill, Str, Dex as you can afford</li>
        <li>250/20 Raven Frost (it{"'"}s cheaper to get a perfect Raven than to buy better SCs)</li>
        <li>High Mana, High AR Ring with min dmg and stats. Mana is critical on Bowazon after 2.4 patch. Without mana on gear you can{"'"}t keep spamming Multi, even when constantly drinking mana pots. Seriously, get a mana ring.</li>
    </ul>
    <h3>Swap:</h3>
    <ul>
        <li>High ED/5 LL Titans (to have as high LL Bow side as possible)</li>
        <li>Stormshield with ED/-req jewel (if you wanna be fancy it can also have +Dex)</li>
    </ul>
    <h3>Match Up Swaps</h3>
    <ul>
        <li>3/11 Res SCs are cheap and nice, swap your FRW/Max Damage/AR for them when you need Res</li>
    </ul>
    <p>Ring: Wisp Projector</p>
    <p>Ring: Dwarf Star (VS who?)</p>
    <p>Armor: Toothrow (Vex) (if you wanna cheese the fire Sorcs)</p>
    <p>Weapon: Faith Shadow Bow (VS who?)</p>
    <p>Helm: 2 Ama 30 FRW 2 Socket Circlet with stats (VS who?)</p>
    <p>Gloves: 20 IAS KB Gloves with extra Res</p>
    <p>Boots: War Traveler (VS who?)</p>
    <p>Belt: Dungo{"'"}s Hearty Cord (VS who?)</p>
    <p>Belt: String of Ears (VS who?)</p>
    <p>Ring: LL Ring (VS who?)</p>
    <p>Amulet: Cat{"'"}s Eye (but I very rarely use it)</p>
    <p>Shield: Phoenix (VS who?)</p>
    <p>Weapon: Optional Rare Javelins (need to be super high ED/IAS to actually beat Titans), use them vs Barb (which is already relatively easy on Titans)</p>
    <h2>Optional: Tele-Bowazon Gear</h2>
    <ul>
        <li>18+ FCR Amulet</li>
        <li>FCR Ring</li>
        <li>Enigma</li>
        <li>20 FCR 45 IAS ED Hat</li>
        <li>Arachnid Mesh</li>
    </ul>
    <h3>Match Up Swaps</h3>
    <p>You want DR vs physical characters, Res vs elemental characters, and OW vs Sorcs.</p>
    <h2>Skill Usage</h2>
    <p>GA is your main attack.</p>
    <p>Summons mess up GA, so you use Multi to remove them.</p>
    <p>If you don{"'"}t know what to do, you spam Multi.</p>
    <p>Strafe is sometimes nice if a low Def/Block character tries to stomp you.</p>
    <p>For Barb, you just Fury/Bolt, don{"'"}t let him catch you Bow side.</p>
    <p>Impale is nice and BM.</p>
    <p>Use Valk/Decoy to shield yourself from invis and tank some hits.</p>
    <p>Other than that you just run around and shoot arrows xD.</p>
    <p>Vs Smite, I recommend M{"'"}avina{"'"}s Set (you have no chance to win, with M{"'"}avina{"'"}s Set you will at least look pretty while dying).</p>
</span>
                            ),
                        }
                    ],
                },
                {
                    name: "Assassin",
                    nameImage: assaImg,
                    ruleset: "all",
                    date: "",
                    className: "all",
                    difficulty: "",
                    sections: [
                        {
                            name: "Trapper",
                            content: (
                                <span>
    <h1>Trapper Assassin Guide</h1>
    <p>This guide is intended to provide a basic overview on how to gear up and play your Trap Assassin (Trapper) in PvP. This build focuses on high trap damage and effective battlefield control through constant movement and strategic use of Mind Blast.</p>
    <h2>Stats &amp; Skills</h2>
    <p><strong>Skills</strong></p>
    <p>20 Mind Blast</p>
    <p>20 Lightning Sentry</p>
    <p>14 Charged Bolt Sentry</p>
    <p>20 Shock Web</p>
    <p>20 Fire Blast</p>
    <p>Remaining points in Fade</p>
    <p><strong>Stats</strong></p>
    <p>Strength: As much as needed to wear your gear</p>
    <p>Dexterity: Enough to maintain a high chance to block with Weapon Block</p>
    <p>Vitality: All remaining points</p>
    <p>Energy: None</p>
    <p>All builds contain the following:</p>
    <p>Assassin Torch</p>
    <p>Annihilus</p>
    <p>To make sure you get the correct IAS breakpoints, use the IAS Calculator.</p>
    <p>For most PvP trap assassins, aiming for the following breakpoints is practical and effective:</p>
    <p>FCR: 102%</p>
    <p>FHR: 48%  (86% for 4 frames if possible)</p>
    <h2>Builds</h2>
    <p><strong>Standard Trap Assassin Build</strong></p>
    <p><strong>Main Gear</strong></p>
    <p>Helmet: Griffon{"'"}s Eye (20/15, socketed with Cham)</p>
    <p>Amulet: 2 Assassin Skills, 17 FCR, High Strength, Life Replenish</p>
    <p>Ring 1: FCR, High Strength, Life Replenish, Mana, Resists</p>
    <p>Ring 2: FCR, Life, Mana, Life Replenish, Resists</p>
    <p>Main Hand Claw: GT Claw (2 Assassin Skills, 30 IAS, 2-3 LS, 2 sockets, socketed with 15/15 - 7/4 Life Replenish, Resists jewels)</p>
    <p>Off Hand Claw: Claw (2 Assassin Skills, 3 LS, 2 sockets, low Strength/Dexterity requirements, socketed with 7 FHR, 4 Replenish Life, Resists jewels)</p>
    <p>Armor: Enigma (15 ED Wyrmhide)</p>
    <p>Boots: Shadow Dancer (2 Assassin Skills, 25 Dexterity)</p>
    <p>Gloves: Trang-Oul{"'"}s Claws (or Magefist if high cold resistance isnt needed)</p>
    <p>Belt: Arachnid Mesh</p>
    <h3>Inventory</h3>
    <p>Cube</p>
    <p>Life Trap Grand Charms</p>
    <p>FHR/Mana SCs</p>
    <p>Life/Res SCs</p>
    <p>Life/Mana SCs</p>
    <h3>Cube Precast Gear</h3>
    <p>GT Claws (3 Shadow Skills, 3 Fade, 1 socket, socketed with 5/5 Poison Rainbow Facet) x2</p>
    <p>Amulet: 3 Shadow Skills, high Strength</p>
    <p>Rings: BK Ring x2</p>
    <p>Demon Limb (socketed with 5/5 Fire Rainbow Facet or Hel)</p>
    <h3>Stash Gear</h3>
    <p>Griffon{"'"}s Eye (20/15, socketed with Cham, Lo, or Dol)</p>
    <p>GT Claw (30+ IAS, 2 Assassin Skills, 3 LS, 1 socket, socketed with 15 IAS/30 Poison Resist jewel) [Main Hand Claw]</p>
    <p>GT Claw (+3 Traps, 3LS, 2 socket (2* Fire RBF for vs Necs)</p>
    <p>GT Claw (2 Assassin Skills, 3 LS, 2 sockets, socketed with 7 FHR and 4 Replenish Life jewels) [Off Hand Claw]</p>
    <p>GT Claws (3 Shadow, 3 Venom, 1 socket, socketed with 5/5 Poison Rainbow Facet) x2</p>
    <p>Rings: 10 FCR, high AR, Life Leech x2 (reach 20 Life Leech for Druid pets and Blood Golem Necromancers)</p>
    <p>Trap Grand Charms</p>
    <p>FHR/Poison Resist Small Charms</p>
    <p>Life/Cold Damage Small Charms</p>
    <p>Life/Mana Small Charms</p>
    <p>Life/Resist Small Charms</p>
    <h2>Skill Usage</h2>
    <p>Your goal as a Trapper is to keep opponents under constant pressure with Mind Blast and traps. Always keep moving, avoid camping in one spot, and use Mind Blast frequently to lock down enemies while your traps deal damage. This build aims for 102/48 FCR/FHR, 12k trap damage, 220 Mind Blast damage, 5k Fire Blast damage, 4000+ HP, and 800+ mana.</p>
    <h3>Basic Attacks:</h3>
    <p>Main damage sources are Lightning Sentry and Fire Blast.</p>
    <p>Use Mind Blast to control the battlefield and set up your traps.</p>
    <p>Keep Fade active for additional damage reduction and resistances.</p>
</span>
                            ),
                        },
                        {
                            name: "Hybsin",
                            content: (
                                <span>
    <h1>Hybrid Assassin Guide</h1>
    <p>This guide is intended to provide a basic overview on how to gear up and play your Hybrid Assassin (Hybsin) in PvP. This build is versatile and allows for a wide range of playstyles, depending on your gear and personal preferences. It includes budget-friendly options and suggestions for more advanced gear setups.</p>
    <h2>Stats &amp; Skills</h2>
    <p><strong>Stats</strong></p>
    <p>Strength: As much as needed to wear your gear</p>
    <p>Dexterity: Enough to maintain a high chance to block with Weapon Block</p>
    <p>Vitality: All remaining points</p>
    <p>Energy: None</p>
    <p><strong>Skills</strong></p>
    <p>20 Shock Web</p>
    <p>20 Charged Bolt Sentry (some rules allow only 14 points)</p>
    <p>20 Lightning Sentry</p>
    <p>20 Claw Mastery</p>
    <p>1 point in all Shadow Disciplines</p>
    <p>Remaining points into Mind Blast</p>
    <p>Leftover Points in Fade/Venom/Weapon Block (based on personal preference and gear)</p>
    <p>To make sure you get the correct IAS breakpoints, use the IAS Calculator.</p>
    <h2>Builds</h2>
    <p><strong>Hybsin Build</strong></p>
    <h3>Main Gear</h3>
    <p>Helm: Griffon{"'"}s Eye (20/15, socketed with ED/IAS jewel)</p>
    <p>Main Hand Claw: GT Fools Claw (40 IAS, 3 LS, 2 sockets, socketed with 2 Ums)</p>
    <p>Off Hand Claw: GT Chaos (+LS, +BS mods)</p>
    <p>Armor: Enigma (Mage Plate)</p>
    <p>Amulet: 2 Assassin Skills, 17+ FCR, with additional mods such as replenish life, life, strength, or dexterity</p>
    <p>Rings: 10 FCR, high AR, life, High Replenish Life, Mana x2</p>
    <p>Gloves: Trang-Oul{"'"}s Claws (upped)</p>
    <p>Belt: Arachnid Mesh</p>
    <p>Boots: Gore Rider (upped)</p>
    <h3>Inventory</h3>
    <p>Cube</p>
    <p>Shadow Grand Charms</p>
    <p>AR/Life OR Max Dmg/AR/Life Small Charms</p>
    <h3>Cube Precast Gear</h3>
    <p>GT Claws (3 Shadow, 3 Fade, 1 socket, socketed with 5/5 Poison Rainbow Facet) x2</p>
    <p>Amulet: 3 Shadow Skills, high Strength</p>
    <p>Rings: BK Ring x2</p>
    <p>Demon Limb (socketed with 5/5 Fire Rainbow Facet or Hel)</p>
    <h3>Stash Gear</h3>
    <p>Griffon{"'"}s Eye (20/15, socketed with Cham, Lo, or Dol)</p>
    <p>GT Claw (30+ IAS, 2 Assassin Skills, 3 LS, 1 socket, socketed with 15 IAS/30 Poison Resist jewel) [Main Hand Claw]</p>
    <p>GT Claw (2 Assassin Skills, 3 LS, 2 sockets, socketed with 7 FHR and 4 Replenish Life jewels) [Off Hand Claw]</p>
    <p>GT Claws (3 Shadow, 3 Venom, 1 socket, socketed with 5/5 Poison Rainbow Facet) x2</p>
    <p>Rings: 10 FCR, high AR, Life Leech x2 (reach 20 Life Leech for Druid pets and Blood Golem Necromancers)</p>
    <p>Shadow Dancer (2 Shadow Skills, 25 Dexterity)</p>
    <p>Trap Grand Charms</p>
    <p>FHR/Poison Resist Small Charms</p>
    <p>Life/Cold Damage Small Charms</p>
    <p>Life/Mana Small Charms</p>
    <p>Life/Resist Small Charms</p>
    <h3>Match Up Swaps</h3>
    <p>Vs. Trapper: Use Griffon{"'"}s Eye with Cham, Shadow Dancer boots, and Lightning Resist Small Charms until you reach 200 Lightning Resist.</p>
    <p>Vs. Fire Sorceress: Griffon{"'"}s Eye with Cham, Fire Resist Small Charms.</p>
    <p>Vs. Cold Sorceress: Use Cold Resist Small Charms until you reach 420 Cold Resist, along with Raven Frost ring.</p>
    <h2>Skill Usage</h2>
    <p>The Hybrid Assassin relies on both traps and melee attacks to defeat opponents. Key skills include Lightning Sentry and Mind Blast for controlling the battlefield, and Whirlwind (buffed with Venom and open wounds) for added damage. The build also uses Fade for damage reduction and resistances, making it highly versatile in different matchups.</p>
    <h2>Main Attacks:</h2>
    <p>Main damage sources are Lightning Sentry, Mind Blast, and Whirlwind.</p>
    <p>Use Dragon Talon for quick melee attacks, boosted by Venom and open wounds from your gear.</p>
</span>
                            ),
                        }
                    ],
                },
                {
                    name: "Necromancer",
                    nameImage: necImg,
                    ruleset: "all",
                    date: "",
                    className: "all",
                    difficulty: "",
                    sections: [
                        {
                            name: "Bone",
                            content: (
                                <span>
    <h1>Bone Necromancer Guide</h1>
    <p>This guide is intended to give a basic overview on how to gear up and play your Nec vs most classes. There are other alternative options that you can utilize, but this would be a good starting foundation. Whatever you might want to change depends on your budget, your playstyle, and your personal preferences. The Necro is one of the most flexible classes, as its range of skills allows you to play a mixture of offense/defense.</p>
    <h2>Stats &amp; Skills</h2>
    <p><strong>1v1:</strong></p>
    <p>1 Bone Armor</p>
    <p>20 Teeth, Bone Spear, Bone Wall, Bone Prison</p>
    <p>13 Bone Spirit</p>
    <p>1 Amplify Damage (for TvT use all spare points)</p>
    <p>1 Clay Golem</p>
    <p>1 Golem Mastery</p>
    <p>1 Summon Resist</p>
    <p>1 Iron Golem (optional for Fire Golem)</p>
    <p>1 Fire Golem (optional for Vs Fire Sorc)</p>
    <p>Distribute rest of your points into; clay golem, golem mastery, or blood golem depending on how you would like to improve your clay or blood golem{"'"}s life.</p>
    <p><strong>Stats:</strong></p>
    <p>Strength: As much as you need to wear your gear</p>
    <p>Vitality: All remaining points</p>
    <p>For most PvP Necro, aiming for the following breakpoints is practical and effective:</p>
    <p>FCR: 125%</p>
    <p>FHR: 86% (56% can be acceptable in TvT/MB setup)</p>
    <h2>Builds</h2>
    <h3>Caster Setup</h3>
    <p>Weapon: White Wand (Spear/Golem Mastery/Clay Golem)</p>
    <p>Helm: 2/30/20/Life/1 soc (Jah) Circ</p>
    <p>Amulet: 2/20/Life</p>
    <p>Ring: 10Fcr/Str/Life (Res is a bonus mod)</p>
    <p>Ring: Bul Kathos (Natures if agree to NvN with Oak)</p>
    <p>Boots: Aldurs</p>
    <p>Belt: Arach</p>
    <p>Gloves: Bloodfist</p>
    <p>Shield: 35% FCR Spirit Monarch</p>
    <h3>Inventory</h3>
    <p>9x Life P&amp;B Skillers (can swap for FRW Skillers if you have over 3.3k Life and are playing without oak)</p>
    <p>10x Life/Mana Small Charms (additional mod doesn{"'"}t matter really)</p>
    <p>1x FHR/Additional Mod Small Charm</p>
    <h3>Match Up Item Swaps</h3>
    <p>Nec vs Nec: More FRW Skillers if you have more than 3.3k HP and agree to no oak</p>
    <p>NEC vs FB: Swap to fire res SC and use infernos.</p>
    <h3>Damage Reduction Setup</h3>
    <p>Weapon: White (3/3)</p>
    <p>Helm: 2/30/20/2 soc (Ber/Ber) NEC Circ</p>
    <p>Amulet: 2/20/Life</p>
    <p>Ring: 10Fcr/Str/Life (Res is a bonus mod)</p>
    <p>Ring: 15Dex Raven (druid) Or Wisp/BK (VT)</p>
    <p>Boots: Aldurs OR 30/10/10 Life Rep/Cold Res</p>
    <p>Belt: Verdungo</p>
    <p>Gloves: Trang Gloves</p>
    <p>Shield: 35% FCR Spirit Monarch</p>
    <h3>Inventory</h3>
    <p>5x Life P&amp;B Skillers</p>
    <p>1x FHR P&amp;B Skillers</p>
    <p>3x FRW P&amp;B Skillers</p>
    <p>8x Life/Res Small Charms (10 if you gain FHR from Rep Boots)</p>
    <p>2x FHR/Cold Res Small Charms (if using Aldurs or don{"'"}t get FHR from boots)</p>
    <h3>Match Up Item Swaps</h3>
    <p>Nec vs Druid: Raven Frost ring for Cannot be Frozen and 115+ FRW from Helm or Charms</p>
    <p>Setup Swap 1: White, Arach, 2/20/18+Str amu, CoA (2Ber)</p>
    <p>Setup Swap 2: Hoto, Verdungo, 2/20/18+Str amu, CoA (2*Ber)</p>
    <p>NEC Vs VT: Wisp/BK and 115+ FRW</p>
    <p>NEC Vs Barb: Natures and Life Skillers only (if you prefer FRW swap accordingly)</p>
    <h3>30% Block Setup</h3>
    <p>Weapon: White (3/3)</p>
    <p>Helm: 2/20/Str/Dex/2 soc (Ber/Ber) NEC Circ (11+ Str required for shadow dancers)</p>
    <p>Amulet: 2/20/Dex</p>
    <p>Ring: 10Fcr/Dex/Life (Res is a bonus mod)</p>
    <p>Ring: Natures Peace</p>
    <p>Boots: 25Dex Shadowdancer</p>
    <p>Belt: Verdungo</p>
    <p>Gloves: Trang</p>
    <p>Shield: 35% FCR Spirit Monarch</p>
    <h3>Inventory</h3>
    <p>9x Life P&amp;B Skillers</p>
    <p>10x Life/11 Res Small Charms</p>
    <h3>Match Up Item Swaps</h3>
    <p>NEC Vs Barb: Depending on preference, swap FRW Skillers for Life Skillers</p>
    <p>NEC Vs Barb 1: Hoto, 20Fcr 3s Circ (3Ber), high Str/Dex Ring, 2/20Fcr/Dex amu, IK gloves</p>
    <h3>50% Block Hyb Sin / TvT 75% Block (token) Setups</h3>
    <p>Weapon: Hoto</p>
    <p>Helm: 2/20FCR/Dex/2 soc (Shael/Ber) NEC Circ (Res is bonus mod)</p>
    <p>Amulet: 2/15+FCR/Str/Dex</p>
    <p>Ring: 10Fcr/Dex/Life (Res is a bonus mod)</p>
    <p>Ring: Wisp/BK/Raven</p>
    <p>Boots: 25Dex Shadowdancer</p>
    <p>Belt: Arach</p>
    <p>Gloves: Trang</p>
    <p>Shield: Stormshield (Lo/Ber/Eld - Depending on what you need)</p>
    <h3>Inventory</h3>
    <p>6x Life P&amp;B Skillers</p>
    <p>3x FHR P&amp;B Skillers</p>
    <p>10x Life/Res Small Charms</p>
</span>
                            ),
                        }
                    ],
                },
                {
                    name: "Barbarian",
                    nameImage: barbImg,
                    ruleset: "all",
                    date: "",
                    className: "all",
                    difficulty: "",
                    sections: [
                        {
                            name: "BvA",
                            content: (
                                <span>
    <h1>Barbarian Guide</h1>
    <p>This guide offers a fundamental overview of gearing and playing the Barb across various scenarios. While alternative options exist, this guide provides a solid starting point. Your adjustments will depend on factors such as budget, playstyle, and preferences.</p>
    <h2>Stats &amp; Skills</h2>
    <p><strong>Stats</strong></p>
    <p>Str: As much as you need to wear your gear</p>
    <p>Dex: Max block with full Dex gear &amp; Shout</p>
    <p>Vita: All remaining points</p>
    <p><strong>Skills</strong></p>
    <p>Howl: 1</p>
    <p>Shout: 1(1v1) / 20(TvT)</p>
    <p>Battle Orders: 20</p>
    <p>Battle Command: 0 (CTA)</p>
    <p>Axe Mastery: 20</p>
    <p>Inc Stamina: 1</p>
    <p>Inc Speed: 15(1v1) / 1(TvT)</p>
    <p>Iron Skin: 1</p>
    <p>Natural Resistance: 3(1v1) / 1(TvT)</p>
    <p>Bash, Stun, Concentrate: 1</p>
    <p>Double Swing, Throw, Frenzy: 1(1v1)</p>
    <p>Leap: 1(1v1) / 20(TvT)</p>
    <p>Leap Attack: 1</p>
    <p>Whirlwind: 20</p>
    <p>Berserk: 20(1v1) / 1(TvT)</p>
    <p>For most PvP Barbs, aiming for the following breakpoints is practical and effective:</p>
    <p>FCR: 37%-63% depending on preference (or ping)</p>
    <p>FHR: 48%-86% depending on preference</p>
    <h2>Gear</h2>
    <h3>Weapons</h3>
    <p><strong>Set 1:</strong> Main Hand: High Grief BA (ED%)</p>
    <p>Offhand: 2nd Grief BA</p>
    <p>Offhand: Beast BA (Str &gt; ED)</p>
    <p><strong>Set 2:</strong> Main Hand: Grief War Spike (37+ IAS)</p>
    <p>Offhand: Beast War Spike (Str &gt; ED)</p>
    <p>Special: Open Wounds weapons (Fury runeword/Wiz (Um) etc)</p>
    <p>To make sure you get the correct IAS breakpoints, use the IAS Calculator.</p>
    <h2>Builds</h2>
    <p><strong>Barbarian vs Caster</strong></p>
    <p>Helm: 2/30/20/Life/Stats (AR &amp; Mana) Circlet (ED/IAS Jewel)</p>
    <p>Weapon: Grief Berserker Axe (High IAS/+High Damage), Beast Berserker Axe</p>
    <p>Amulet: Highlords</p>
    <p>Belt: Arach</p>
    <p>Gloves: TrangOuls or Magefist</p>
    <p>Boots: Gore Rider</p>
    <p>Armor: Enigma (MP or DS)</p>
    <p>Ring: Natures Peace</p>
    <p>Ring: FCR/AR/Life/Mana/Stats (Rep Life/Res)</p>
    <h3>Inventory:</h3>
    <p>Max/AR/Life or AR/Life Small/Large/Grand charms (depending on preference)</p>
    <p>FHR/Max/AR Small/Large/Grand charms (depending on preference)</p>
    <p>Life or FHR/Res Small charms (enough to get max res depending on your match up)</p>
    <p>1x Poison SC (451 pref.)</p>
    <p><strong>Cube:</strong> 6 Bo/X Shout Helm, 2xSoJ, 3 War Cry amulet, Arach belt, Demon Limb (Fire Facet)</p>
    <h3>Matchup Swaps</h3>
    <p>Trapper: 4 FHR/LR charms, Guillaume{"'"}s Face (ED/IAS jewel), 2 Griefs, Tgods belt, 2 FCR rings.</p>
    <p>Hybrid Assassin: Same as Trapper, stack poison res.</p>
    <p>ES Sorceresses: Stack Open wounds &amp; res. 105 FCR setup with Wizzy Um for Energy Shield.</p>
    <p>Fire Sorc: Vex/Vex/Cham Circlet</p>
    <p>Cold Sorc: 2 Barb/20FCR/Cold Res/Stats (Life/AR/2soc) Circlet</p>
    <p>Necromancer: Grief War Spike &amp; Beast War Spike, ED/IAS / 20FCR Circlet (3* ED/IAS Jewel)</p>
    <p>Hammerdin: Guillaume{"'"}s Face (AR/IAS jewel), Angelic Amu &amp; Rings, Grief + Beast BA, Hsarus Belt &amp; Boots (optional)</p>
    <p>Smiter: CoA (2Ber), Angelic Amu &amp; Rings, Grief + Beast BA, 75 Light Res.</p>
    <p>Druid: LL FCR ring, String of Ears, 3 Ber circlet, FCR amulet, Raven Frost.</p>
    <h3>75% Max Block/TvT</h3>
    <p>Helm: Arreats (AR/IAS Jewel)</p>
    <p>Weapon: Grief Berserker Axe (High IAS/+High Damage)</p>
    <p>Shield: Stormshield (Eld)</p>
    <p>Amulet: Highlords</p>
    <p>Belt: Arachnid</p>
    <p>Gloves: Trang Ouls or Magefist Gloves</p>
    <p>Boots: Gore Rider</p>
    <p>Armor: Enigma (MP or DS)</p>
    <p>Ring: Natures Peace</p>
    <p>Ring: Raven</p>
    <h3>Matchup Swaps</h3>
    <p>BvB: 5 Bo / Visio (ED/IAS) helm, Steelrend gloves, Verdungo, Sacred Fortitude, Angelica Rings &amp; Amu, all Max Dmg/AR/Life charms</p>
    <p>Vs Light: 2 Barb/20FCR/2soc (Lo/Cham) circlet (extra stats such as FRW/Life/AR/Res are a bonus)</p>
    <h3>Inventory:</h3>
    <p>8x War Cry charms</p>
    <p>8x AR/Life SCs</p>
    <p>1x Poison SC (451 pref.)</p>
    <p><strong>Cube:</strong> 6 Bo/X Shout Helm, 2xSoJ, 3 War Cry amulet, Arach belt, Demon Limb (Fire Facet)</p>
    <h2>Skill Usage</h2>
    <p>The Barbarian is highly dependent on Whirlwind and Berserk, utilizing Teleport for mobility and Shout/Battle Command/Battle Orders to enhance survivability and damage output.</p>
    <h2>Main Attacks:</h2>
    <p>Whirlwind and Berserk</p>
    <p>-Leap support in TvT</p>
</span>
                            ),
                        }
                    ],
                },
                {
                    name: "Paladin",
                    nameImage: palaImg,
                    ruleset: "all",
                    date: "",
                    className: "all",
                    difficulty: "",
                    sections: [
                        {
                            name: "VT",
                            content: (
                                <span>
    <h1>VT Paladin Guide</h1>
    <p>This guide is intended to give a basic overview on how to gear up and play your VT vs most classes. There are other alternative options that you can utilize, but this would be a good starting foundation. Whatever you might want to change depends on your budget, your playstyle, and your personal preferences. The VT is often the most fun class to PvP and learn mechanics with, as its range of skills allows you to produce combos to claim the win.</p>
    <h2>Stats &amp; Skills</h2>
    <p><strong>1v1:</strong></p>
    <p>1 Holy Bolt, Blessed Hammer</p>
    <p>20 Smite</p>
    <p>1 Charge (optional with spare points after maximum smite damage/preferred FoH damage is achieved)</p>
    <p>20 Holy Shield</p>
    <p>1 Sacrifice, Zeal, Vengeance, Conversion</p>
    <p>1 Defiance, Prayer, Cleansing, Vigor</p>
    <p>1 Might, Blessed Aim, Concentration, Holy Fire, Holy Freeze</p>
    <p>20 Fanaticism</p>
    <p>1 Thorns, Sanctuary, Conviction (optional with remaining points - will diminish your FoH damage)</p>
    <p>Fist of Heaven/Holy Shock (experiment with different ratios using remaining points to maximize FoH damage)</p>
    <p><strong>Stats:</strong></p>
    <p>Strength: As much as you need to wear your gear</p>
    <p>Dexterity: As much as you need to get max block with full Dex gear &amp; Holy Shield</p>
    <p>Vitality: All remaining points</p>
    <p>To make sure you get the correct IAS breakpoints, use the IAS Calculator.</p>
    <p>For most PvP VTs, aiming for the following breakpoints is practical and effective:</p>
    <p>FCR: 75% Or 125%</p>
    <p>FHR: 48% (86% is optional)</p>
    <h2>Builds</h2>
    <h3>VT vs Caster</h3>
    <p>Weapon: Grief Phaseblade (High IAS/+High Damage)</p>
    <p>Helm: 2/30/20/Life/1Soc (ED/IAS jewel) Circ</p>
    <p>Amulet: 2/20/Dex</p>
    <p>Shield: 35% FCR Spirit Sacred Targe (ED/AR Mod)</p>
    <p>Ring: 10FCR/Dex/Life (rep life/mana/res mods or alternative ring swaps are a bonus)</p>
    <p>Ring: Natures Peace</p>
    <p>Boots: Gore Rider</p>
    <p>Belt: Arach</p>
    <p>Armor: Enigma</p>
    <p>Gloves: Magefist</p>
    <h3>Inventory</h3>
    <p>9x Life PCombat Skillers</p>
    <p>10x Max Damage/Life/AR Small Charms (if you utilize charge)</p>
    <p>10x Life/Mana Small Charms (if you don{"'"}t use charge)</p>
    <h3>Match Up Swaps</h3>
    <p>Trapper: Wisp, 2/20/Life/2s (Cham/Lo) Circ (Frw/Res is bonus), Life/Light or Fire Res Small Charms (until 200LR and max FR)</p>
    <p>Fire Vita/ES: 20% FCR/3Soc (Cham/2Vex) Circ, Life/Fire Res Small Charms</p>
    <p>Fire Vita/ES2: Infernos, 2/20/Life/2Soc (Cham/ED/IAS) Circ (FRW is bonus), Life/Fire Res Small Charms</p>
    <p>Cold Vita/ES: 30FRW/Cold Res Boots, 2/20/Cold Res/2Soc (30 Cold Res/15IAS Jewels) Circ (Or any other kind of FCR/Cold Res), Life/Cold Res Small Charms (until 420), Ravenfrost</p>
    <p>Light Vita/ES: 2/20/Life/2 Soc (Lo/Cham) Circ (Or any other kind of FCR/Light Res if you need it - Life/FRW is a bonus), Life/Light Res Small Charms (until 420), Wisp</p>
    <h3>VT Damage Reduction 125% FCR</h3>
    <p>Weapon: Grief Phaseblade (High IAS/+High Damage)</p>
    <p>Helm: 20 FCR 3Soc (3Ber) Circ</p>
    <p>Amulet: 2/20/Dex</p>
    <p>Shield: 35% FCR Spirit Sacred Targe (ED/AR Mod)</p>
    <p>Ring: 10FCR/Dex/Life (rep life/mana/res mods or alternative ring swaps are a bonus)</p>
    <p>Ring: Ravenfrost</p>
    <p>Boots: Gore Rider</p>
    <p>Belt: Arach</p>
    <p>Armor: Enigma</p>
    <p>Gloves: Trang Oul or Magefist (depending on your Cold Res)</p>
    <h3>75% FCR</h3>
    <p>Gloves: Draculs</p>
    <p>Belt: Verdungo</p>
    <h3>Inventory</h3>
    <p>9x Life PCombat Skillers</p>
    <p>Cold Life Small Charms (Until you get 75 Cold Res)</p>
    <p>Max Damage/Life/AR Small Charms (if you use charge)</p>
    <p>Life/Mana Small Charms (if you use FoH)</p>
    <h3>Match Up Swaps</h3>
    <p>Hybsin: Thundergods</p>
    <h2>Skill Usage</h2>
    <p>The VT is highly dependent on Smite, utilizing FoH to stun your enemy when swirled, or for general chip damage. Your first goal is to always have the following skills up:</p>
    <p><strong>Holy Shield</strong></p>
    <p><strong>Basic Attacks:</strong></p>
    <p>Main damage source is Smite/Fana or FoH</p>
</span>
                            ),
                        },
                        {
                            name: "Hammerdin",
                            content: (
                                <span>
    <h1>Hammerdin Guide</h1>
    <p>This guide is intended to give a basic overview on how to gear up and play your Hammer vs most classes. There are other alternative options that you can utilize, but this would be a good starting foundation. Whatever you might want to change depends on your budget, your playstyle, and your personal preferences. The Hammer is often the easiest class to play but the hardest to master and learn mechanics with.</p>
    <h2>Stats &amp; Skills</h2>
    <p><strong>1v1:</strong></p>
    <p>1 Holy Bolt</p>
    <p>20 Blessed Hammer</p>
    <p>1 Smite (remaining points at the end can be used on smite to increase swirl duration for FoH combo)</p>
    <p>1 Charge</p>
    <p>1 Holy Shield</p>
    <p>1 Sacrifice, Zeal, Vengeance, Conversion</p>
    <p>Fist of Heaven/Holy Shock (experiment with different ratios using remaining points to maximize FoH damage)</p>
    <p>1 Defiance, Prayer, Cleansing</p>
    <p>20 Vigor</p>
    <p>1 Might, Blessed Aim</p>
    <p>20 Blessed Aim, Concentration</p>
    <p>1 Fanaticism</p>
    <p><strong>Stats:</strong></p>
    <p>Strength: As much as you need to wear your gear</p>
    <p>Dexterity: As much as you need to get max block with full Dex gear &amp; Holy Shield</p>
    <p>Vitality: All remaining points</p>
    <p>For most PvP Hammerdin, aiming for the following breakpoints is practical and effective:</p>
    <p>FCR: 125%</p>
    <p>FHR: 48% (86% is optional)</p>
    <h2>Builds</h2>
    <h3>Hammer vs Caster</h3>
    <p>Weapon: Hoto</p>
    <p>Helm: Shako (Jah)</p>
    <p>Amulet: 2/20/Life</p>
    <p>Shield: 35% FCR Spirit Sacred Targe (45@)</p>
    <p>Ring: 10FCR/Life (rep life/mana/res mods are a bonus)</p>
    <p>Ring: Bul Kathos</p>
    <p>Boots: Waterwalk</p>
    <p>Belt: Arach</p>
    <p>Armor: Enigma</p>
    <p>Gloves: Magefist or Trang Oul{"'"}s</p>
    <h3>Inventory</h3>
    <p>9x Life PCombat Skillers</p>
    <p>Life/Mana or Life/Res Small Charms</p>
    <h3>Match Up Swaps</h3>
    <p>Trapper: Wisp, 2/20/Life/2s (Cham/Lo) Circ (Frw/Res is bonus), Life/Light or Fire Res Small Charms (until 200LR and max FR)</p>
    <p>Fire Vita/ES: 20% FCR/3Soc (Cham/2Vex) Circ, Life/Fire Res Small Charms</p>
    <p>Fire Vita/ES2: Infernos, 2/20/Life/2Soc (Cham/ED/IAS) Circ (FRW is bonus), Life/Fire Res Small Charms</p>
    <p>Cold Vita/ES: 30FRW/Cold Res Boots, 2/20/Cold Res/2Soc (30 Cold Res/15IAS Jewels) Circ (Or any other kind of FCR/Cold Res), Life/Cold Res Small Charms (until 420), Ravenfrost</p>
    <p>Light Vita/ES: 2/20/Life/2 Soc (Lo/Cham) Circ (Or any other kind of FCR/Light Res if you need it - Life/FRW is a bonus), Life/Light Res Small Charms (until 420), Wisp</p>
    <h3>Hammer Damage Reduction 125% FCR</h3>
    <p>Weapon: Hoto</p>
    <p>Helm: CoA (2Ber)</p>
    <p>Amulet: 2/20/Str (enough to wear CoA)</p>
    <p>Shield: 35% FCR Spirit Sacred Targe (45@ or ED/AR if you have enough res)</p>
    <p>Ring: 10FCR/Str/Dex Ring (rep life/mana/res mods or alternative ring swaps are a bonus)</p>
    <p>Ring: Ravenfrost</p>
    <p>Boots: Waterwalk</p>
    <p>Belt: Verdungo</p>
    <p>Armor: Enigma</p>
    <p>Gloves: Trang Oul or Magefist Gloves (depending on your Cold Res)</p>
    <h3>Inventory</h3>
    <p>9x Life PCombat Skillers</p>
    <p>Life/Mana or Life/Res Small Charms</p>
    <h3>Match Up Swaps</h3>
    <p>Hybsin: Thundergods</p>
    <h2>Skill Usage</h2>
    <p>Your first goal is to always have the following skills up:</p>
    <p><strong>Holy Shield</strong></p>
    <p><strong>Basic Attacks:</strong></p>
    <p>Main damage source is Hammer/Concentration</p>
</span>
                            ),
                        }
                    ],
                },
                {
                    name: "Sorceress",
                    nameImage: sosoImg,
                    ruleset: "all",
                    date: "",
                    className: "all",
                    difficulty: "",
                    sections: [
                        {
                            name: "Vita (Fire)",
                            content: (
                                <span>
    <h1>Vita Fire Sorceress Guide</h1>
    <p>This guide offers insights into gearing up and playing a Vita Sorceress in Diablo 2.</p>
    <h2>Stats &amp; Skills</h2>
    <p><strong>Stats:</strong></p>
    <p>Strength: Allocate as needed for equipment.</p>
    <p>Dexterity: Invest enough for block with full Dex gear.</p>
    <p>Vitality: Assign all remaining points.</p>
    <p><strong>1v1 Fire:</strong></p>
    <p>20 Firebolt, Fireball</p>
    <p>1 Warmth, Enchant, Fire Wall, Inferno</p>
    <p>20 Meteor, Fire Mastery</p>
    <p>1 Teleport</p>
    <p>1 Chain Lightning, Thunder Storm, Energy Shield (optional with remaining points)</p>
    <p>For most PvP Vita Fire Sorc, aiming for the following breakpoints is practical and effective:</p>
    <p>FCR: 105%</p>
    <p>FHR: 87%</p>
    <h2>Builds</h2>
    <h3>Vita Vs Caster Setup</h3>
    <p>Weapon: Obsession</p>
    <p>Armor: Enigma</p>
    <p>Gloves: Magefist</p>
    <p>Head: COA (Cham/Ber) vs Druid / (Ber/Lo) vs Smiter)</p>
    <p>Amulet: +2/20 FCR/Str/Life</p>
    <p>Rings: 2x BK ring vs druid / BK ring + wisp vs smiter</p>
    <p>Belt: Arach</p>
    <p>Inventory: 9x Skiller GCs, life/light res SCs (vs VT), life/cold res SCs (vs druid if need CR to stack 175 to reduce hurricane dmg), life/mana SCs</p>
    <h3>200 FCR Fire Setup</h3>
    <p>Shield: Spirit 35fcr</p>
    <p>Armor: Vipermagi (5/5 fire rbf)</p>
    <p>Weapon: Hoto</p>
    <p>Gloves: Magefist</p>
    <p>Boots: 15/15 sandstorm treks</p>
    <p>Head: +2/20fcr/Str/Life circ or +3Fire/20/2os circlet</p>
    <p>Amulet: +2/15+Fcr/Str/Life</p>
    <p>Rings: 10fcr/Str/Life x2</p>
    <p>Belt: Arach</p>
    <p>Inventory: 9x skiller GC</p>
    <h3>Damage Reduction Setup</h3>
    <p>Weapon: Obsession (Preferably a 3fb 3fm 3 additional mod such as meteor base. 25% extra life roll is a beneficial)</p>
    <p>Armor: Enigma</p>
    <p>Gloves: Magefist</p>
    <p>Boots: Aldurs</p>
    <p>Head: COA (Cham/Ber) vs druid / COA (Ber/Lo) vs smiter</p>
    <p>Amulet: +2/20fcr/str/life</p>
    <p>Rings: 2x BK ring vs druid / BK ring + wisp vs smiter</p>
    <p>Belt: Verdungo</p>
    <p>Inventory: 9x Skiller GC, life/light res SCs (vs vt), life/cold res SCs (vs druid if need cr to stack 175 to reduce hurricane dmg), life/mana scs</p>
    <h3>Maxblock Setup Guide</h3>
    <p>Shield: Stormshield (ELD)</p>
    <p>Armor: Enigma (DS or AP)</p>
    <p>Weapon: Hoto</p>
    <p>Gloves: Magefist (upgrade twice)</p>
    <p>Boots: Shadowdancers (25 Dex)</p>
    <p>Head: Circlet (+2 skills, +20% FCR, High Dex, 2 sockets (7% FHR/high Dex/high PR jewels)</p>
    <p>Amulet: +2 skills, +15% FCR, High Dex, High Life, Resists</p>
    <p>Rings: 10% FCR, Dex, Life, Resists + Nature{"'"}s Peace/Bul-Kathos{"'"} /Raven Frost</p>
    <p>Belt: Verdungo{"'"}s (vs Barb/Amazon) or Thundergod{"'"}s (vs Hybrid Assassin)</p>
</span>
                            ),
                        },
                        {
                            name: "Energy Shield (Fire)",
                            content: (
                                <span>
    <h1>Energy Shield Sorceress Guide</h1>
    <p>This guide is intended to provide a basic overview of how to gear up and play your ES Sorceress in PvP encounters. With an 82% Energy Shield, you{"'"}ll be able to mitigate damage effectively while dishing out devastating fireballs. Let{"'"}s dive into the builds!</p>
    <h2>Stats &amp; Skills</h2>
    <p><strong>Fire 1v1:</strong></p>
    <p>1 Warmth</p>
    <p>20 Firebolt, Fireball, Meteor, Fire Mastery</p>
    <p>20 Telekinesis,</p>
    <p>1 Teleport</p>
    <p>1 Energy Shield</p>
    <p><strong>Stats:</strong></p>
    <p>Strength: Enough for gear</p>
    <p>Dexterity: Enough for max block</p>
    <p>Vitality &amp; Energy: With 82ES, the majority of sorcs tend to put more into vita to balance out their Life/Mana</p>
    <p><strong>All builds contain the following:</strong></p>
    <p>Life Fire Skillers</p>
    <p>Life/Mana Small Charms (as many as you can use)</p>
    <p>For most PvP ES Sorc, aiming for the following breakpoints is practical and effective:</p>
    <p>FCR: 105%</p>
    <p>FHR: 87% (30%+ is fine in MB gear)</p>
    <h3>Cube Precast Gear</h3>
    <p>+9 ES Memory Runeword Staff</p>
    <p>Amulet: 3 Lightning Skills (high Strength optional)</p>
    <p>2xLightning Skillers</p>
    <h2>Builds</h2>
    <h3>Fireball Sorceress Vs Caster</h3>
    <p>Amulet: 2/10/High Str/Mana (Res is mega bonus)</p>
    <p>Helm: Shako (Sur)</p>
    <p>Weapon: Hoto</p>
    <p>Shield: Spirit Monarch</p>
    <p>Armor: Enigma</p>
    <p>Gloves: Frostburn</p>
    <p>Boots: 10Fhr/High Mana/High Increase Maximum Mana is a must (FRW/Res is a mega bonus)</p>
    <p>Rings: 2xSoJs</p>
    <p>Belt: Arach</p>
    <h3>Match Up Swaps</h3>
    <p>Necro: Shako (Jah) &amp; Waterwalks</p>
    <p>DR: 2/20/High Mana/2 soc (2x Ber) Circ, Verdungo, Eschutas, Mana Boots with Rep Life/CR</p>
    <h3>50% Max Block Fireball Sorceress</h3>
    <p>Helm: 2/20/High Dex/2soc Circ (FHR/Dex/Res/Rep/Str Jewels (depending on your preference/requirements))</p>
    <p>Armor: Enigma</p>
    <p>Shield: Upgraded Whistans (Eld)</p>
    <p>Amulet: 2/5FCR/High Dex/Adds (Mana/Res/Str)</p>
    <p>Weapon: Hoto</p>
    <p>Rings: 2xSojs</p>
    <p>Belt: Arach</p>
    <p>Boots: Eth Sands</p>
    <p>Gloves: Magefists</p>
    <h3>75% Block</h3>
    <p>Shield: Stormshield (eld)</p>
    <p>Helm: 2/20/High Str/High Dex Circ (Fhr/Dex/Res/Rep Jewels)</p>
    <p>Amulet: 2/5FCR/High Str/High Dex/Adds (Res/Mana)</p>
    <p>Armor: Eth Gladiator Bane, Que Hagans or Stone Runeword Armor (depending on preference)</p>
    <h2>Skill Usage</h2>
    <p>The Fireball Sorceress excels in dealing fire damage from a distance while using Energy Shield to absorb incoming damage.</p>
    <p><strong>Basic Attacks:</strong></p>
    <p>Main damage source is Fireball &amp; Meteor</p>
    <p>Swap to Firebolt when mana is low</p>
</span>
                            ),
                        }
                    ],
                },
                {
                    name: "Druid",
                    nameImage: duduImg,
                    ruleset: "all",
                    date: "",
                    className: "all",
                    difficulty: "",
                    sections: [
                        {
                            name: "Elemental",
                            content: (
                                <span>
    <h1>Elemental Druid Guide</h1>
    <p>This Guide is intended to give a basic overview on how to generally gear up and play your Druid vs most classes. There are various other options you can utilize, depending on your budget, playstyle, and personal preferences. The Druid is often one of the cheaper classes if you want to play PvP. I hope this guide will help you with planning and getting into this funny and strong class.</p>
    <h2>Stats &amp; Skills</h2>
    <p><strong>Skill Tree:</strong></p>
    <p>1 Arctic Blast</p>
    <p>20 Cyclone Armor</p>
    <p>20 Twister</p>
    <p>20 Tornado</p>
    <p>20 Hurricane</p>
    <p>1 Raven</p>
    <p>1 Spirit Wolf</p>
    <p>1 Dire Wolf</p>
    <p>20 Oak Sage</p>
    <p>Rest Bear</p>
    <p><strong>Stats:</strong></p>
    <p>Strength: As much as you need to wear your gear</p>
    <p>Dexterity: As much as you need to wear your gear</p>
    <p>Vitality: All remaining points</p>
    <p>For most PvP Druids, aiming for the following breakpoints is practical and effective:</p>
    <p>FCR: 163%</p>
    <p>FHR: 65%, 86% or 99% based on preference.</p>
    <h2>Gearing &amp; Builds</h2>
    <h3>Inventory</h3>
    <p>9x Life Elemental Skillers</p>
    <p>2-3x FHR Elemental Skillers</p>
    <p>10x Life/Mana Small Charms</p>
    <p>1-2x FHR/Mana Small Charms</p>
    <p>Plenty of Life/Res Small Charms</p>
    <h3>Caster Setup:</h3>
    <p>Headgear: 2 Druid/30 FRW/20 FCR/Life/1 Socket (Shael)</p>
    <p>Armor: Enigma</p>
    <p>Shield: 35 FCR Spirit Monarch</p>
    <p>Boots: Aldurs or Blood Boots 30 FRW/10 FHR/Replife/Appropriate Res</p>
    <p>Belt: Arachnid</p>
    <p>Weapon: Hoto</p>
    <p>Amulet: 2 Druid/18+ FCR/Life and/or Str</p>
    <p>Ring 1: Bul-Kathos or Soj</p>
    <p>Ring 2: 10 FCR/Str/Life</p>
    <p>Gloves: Magefist</p>
    <h3>Match up Item Swaps:</h3>
    <p>Vs Trapper: 2/30/20/life/1 Socket (Lo), Rep/Res boots, Wisp</p>
    <h3>Damage Reduction Setup:</h3>
    <p>Headgear: 2 Druid/20 FCR/Life/2 Sockets (BerBer Circlet)</p>
    <p>Shield: 35 FCR Spirit Monarch</p>
    <p>Boots: Shadowdancers</p>
    <p>Belt: Verdungos</p>
    <p>Weapon: Suicide Branch</p>
    <p>Amulet: 2 Druid/18+ FCR/Dex/Life or Str</p>
    <p>Ring 1: 10 FCR/Dex/Life/Str</p>
    <p>Ring 2: 10 FCR/Dex/Life/Str</p>
    <p>Gloves: Magefist</p>
    <h2>Skill Usage</h2>
    <p>The Druid is highly dependent on his summons. Your first goal is to always have the following skills up:</p>
    <p>Hurricane</p>
    <p>Oak Sage</p>
    <p>Ravens</p>
    <p>Bear or Wolves (depending on matchup)</p>
    <p>Cyclone Armor</p>
    <h2>Basic Attacks:</h2>
    <p>Main damage source is Tornado</p>
</span>
                            ),
                        }
                    ],
                }
            ]
        },
        {
            tabName: "d2a",
            name: "d2a",
            image: d2a,
            discord_invite: "https://discord.gg/R6vg2DbdhZ",
            tours: [
                {
                    name: "Mid Level Duel tour",
                    ruleset: "mld",
                    time: "18 cet",
                    day: "Monday",
                    participants: "",
                    participants_current: "8",
                    participants_max: "16",
                    prize: "???",
                },
                {
                    name: "1v1 D2A tour",
                    ruleset: "standard",
                    time: "18 cet",
                    day: "Tuesday",
                    participants: "",
                    participants_current: "8",
                    participants_max: "16",
                    prize: "???",
                },
                {
                    name: "Clan Wars",
                    ruleset: "tvt",
                    time: "18 cet",
                    day: "Sunday",
                    participants: "",
                    participants_current: "8",
                    participants_max: "16",
                    prize: "???",
                }
            ],
            streams: ["d2_arena", "ak_fen0", "h4r9"],
            ruleSets: [{
                name: "standard",
                content: <span style={{
                    display: 'block',
                    padding: '20px',
                    backgroundColor: '#f0f0f0',
                    border: '1px solid #ccc',
                    borderRadius: '5px'
                }}>

      <h2 style={{color: '#142d4c', marginTop: '0'}}>General Rules</h2>
      <ul style={{marginTop: '0', paddingLeft: '20px'}}>
        <li style={{marginBottom: '10px'}}>All mods, hacks, programs are banned. (WMC and other mouse-window capture programs are allowed.)</li>
        <li style={{marginBottom: '10px'}}>Toxic attitude and arguing with the host/moderator may lead to loss/disqualification.</li>
        <li style={{marginBottom: '10px'}}>Refusal to show gear, inventory, and/or weapon swap will result in suspension.</li>
        <li style={{marginBottom: '10px'}}>The Moor is to be cleared before dueling.</li>
        <li style={{marginBottom: '10px'}}>Both players must signal the start of the duel.</li>
        <li style={{marginBottom: '10px'}}>No offensive abilities or curses can be casted prior to your opponent signaling the start of the duel.</li>
        <li style={{marginBottom: '10px'}}>Towning or teleporting past Flavie and bridges may result in a loss, unless it{"'"}s done non-deliberately.</li>
        <li style={{marginBottom: '10px'}}>No use of houses (accidentally entering the house is excused).</li>
        <li style={{marginBottom: '10px'}}>No use of shrines or wells - Bridge maps should be used.</li>
        <li style={{marginBottom: '10px'}}>Recasting is allowed.</li>
        <li style={{marginBottom: '10px'}}>Ties are null duels.</li>
        <li style={{marginBottom: '10px'}}>Refusal to duel results in a loss.</li>
        <li style={{marginBottom: '10px'}}>Overly defensive play will result in disqualification.</li>
        <li style={{marginBottom: '10px'}}>Players may not leave battle orders down or drop gear to rep life faster.</li>
        <li style={{marginBottom: '10px'}}>If you get to very low life (& mana for ES sorc), continue dueling to the finish. Running to replenish may lead to Loss/DQ.</li>
        <li style={{marginBottom: '10px'}}>Stream sniping is prohibited.</li>
        <li style={{marginBottom: '10px'}}>If you have been disconnected, you may only enter the battlefield again if opponents agree to let you do so.</li>
        <li style={{marginBottom: '10px'}}>Win trading is prohibited.</li>
        <li style={{marginBottom: '10px'}}>If you or your team forfeits, the player/team from the past round advances to the next stage.</li>
        <li style={{marginBottom: '10px'}}>Having resolution higher than 16:9 is not allowed.</li>
      </ul>

      <h2 style={{color: '#142d4c'}}>Items</h2>
      <ul style={{marginTop: '0', paddingLeft: '20px'}}>
        <li style={{marginBottom: '10px'}}>No item charges are allowed with the exception of Oak Sage, Ravens, Enchant, Teleport, and Heart of Wolverine.</li>
        <li style={{marginBottom: '10px'}}>Slow is capped at 10%.</li>
        <li style={{marginBottom: '10px'}}>Max resistance is 85% (Exceptions are listed under class allowances).</li>
        <li style={{marginBottom: '10px'}}>No more than 450 total cold resistance (Bonus displayed on character screen) or 420 total cold resistance with Raven Frost.</li>
        <li style={{marginBottom: '10px'}}>Max absorb is 20%.</li>
        <li style={{marginBottom: '10px'}}>Cannot use flat absorb in combination with % absorb to the same element.</li>
        <li style={{marginBottom: '10px'}}>When using absorb (% or flat), do not exceed 80% max resistance to the same element (exception for TGods/Phoenix).</li>
        <li style={{marginBottom: '10px'}}>No more than one poison damage small charm is allowed.</li>
        <li style={{marginBottom: '10px'}}>Life rep is capped at 33, NOT including + rep from CTA.</li>
        <li style={{marginBottom: '10px'}}>Only one item that grants CtC Life Tap can be worn at a time.</li>
      </ul>
    </span>
            }],
            guides: [
            ]
        }
    ]);

    const [recipesAndMisc] = useState([
        {
            name: "Upping",
            content: (
                <div>
                    <div style={{"color": "white"}}>
                        TAGS: UPPING DEFENSE; PERFECT DEFENSE; PERFECT UPPING DEFENSE; PERFECT UPPED DEFENSE; UNIQUE
                        UPPED DEFENSE;
                    </div>
                    <div>
                        <img src="https://i.postimg.cc/44YRy8DH/uppeds.jpg" alt="Upped Defense"/>
                    </div>
                </div>
            ),
        },
        {
            name: "Sockets",
            content: (
                <div>
                    <div style={{"color": "white"}}>
                        TAGS: SOCKETS; SOCKET RECIPE; EBUG RECIPE; ETH ARMOR; ETHEREAL ARMOR; SOCKETED; SOCKET ARMOR;
                        SHIELD; HELMET; ARMOR;
                    </div>
                    <div>
                        <img src="https://i.postimg.cc/hP19Rbjg/sockets.jpg" alt="Sockets"/>
                    </div>
                </div>
            ),
        },
        {
            name: "Base Upgrades",
            content: (
                <div>
                    <div style={{"color": "white"}}>
                        TAGS: UPPING RECIPE; UP RECIPE; NORMAL UNIQUE ARMOR; EXCEPTIONAL UNIQUE ARMOR; ELITE UNIQUE
                        ARMOR; NORMAL RARE ARMOR; EXCEPTIONAL RARE ARMOR; ELITE RATE ARMOR;
                    </div>
                    <div>
                        <img src="https://i.postimg.cc/xdJJ8K0X/upgrades.jpg" alt="Upgrades"/>
                    </div>
                </div>
            ),
        },
        {
            name: "Re-Rolls",
            content: (
                <div>
                    <div style={{"color": "white"}}>
                        TAGS: REROLLING MAGIC ITEM; REROLLING SMALL CHARM; REROLLING GRAND CHARM;
                    </div>
                    <div>
                        <img src="https://i.postimg.cc/ncZ4hW5n/fixed.jpg" alt="Rerolling"/>
                    </div>
                </div>
            ),
        },
        {
            name: "Blood Crafting",
            content: (
                <div>
                    <div style={{"color": "white"}}>
                        TAGS: CRAFTING RECIPE; BLOOD CRAFTING; BLOOD HELM; BLOOD BOOTS; BLOOD BELT; BLOOD RING;
                    </div>
                    <div>
                        <img src="https://i.postimg.cc/Kc9yLrBk/bloodklar2.jpg" alt="Blood Crafting"/>
                    </div>
                </div>
            ),
        },
        {
            name: "Power & Caster Crafting",
            content: (
                <div>
                    <div style={{"color": "white"}}>
                        TAGS: CRAFTING RECIPE; CASTER RING; CASTER AMULET; CASTER CRAFTING; POWER CRAFTING; POWER GLOVES; POWER BELT;
                    </div>
                    <div>
                        <img src="https://i.postimg.cc/NMwTTx7P/4donepsdupdate.jpg" alt="Caster Crafting"/>
                    </div>
                </div>
            ),
        },
        {
            name: "Runes",
            content: (
                <div>
                    <div style={{"color": "white"}}>
                        TAGS: RUNE UPGRADING; RUNES; PUL; UM; MAL; IST; GUL; VEX; OHM; LO; SUR; BER; JAH; CHAM; ZOD
                    </div>
                    <div>
                        <img src="https://i.postimg.cc/KvkKvCm0/runesklar2-kopia.jpg" alt="Runes"/>
                    </div>
                </div>
            ),
        },
        {
            name: "Mercenaries",
            content: (
                <div>
                    <div style={{"color": "white"}}>
                        TAGS: ACT 2 MERC; A2 AURA; A2 OFFENSIVE; A2 OFFENSE; A2 OFFENCEC; A2 DEFENSIVE; A2 DEFENSIFE; A2 COMBAT;
                    </div>
                    <div>
                        <img src="https://i.postimg.cc/PqdrYJkB/mercs.jpg" alt="Mercs"/>
                    </div>
                </div>
            ),
        },
        {
            name: "Skins",
            content: (
                <div>
                    <div style={{"color": "white"}}>
                        TAGS: SKINS; CHARM SKINS; RING SKINS; JEWEL SKINS;
                    </div>
                    <div>
                        <img src="https://i.postimg.cc/RFN8mK0R/skins-kopia.jpg" alt="Skins"/>
                    </div>
                </div>
            ),
        },
    ]);
    const [bpClasses] = useState([{
        name: "Amazon",
        image: amaImg,
        content: (
            <div>
                <h3>Amazon FHR</h3>
                <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                    <tr>
                        <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>%</th>
                        <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Frames</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>0%</td>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>11</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>6%</td>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>10</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>13%</td>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>9</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>20%</td>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>8</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>32%</td>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>7</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>52%</td>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>6</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>86%</td>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>5</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>174%</td>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>4</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>600%</td>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>3</td>
                    </tr>
                    </tbody>
                </table>

                <h3>Amazon FCR</h3>
                <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                    <tr>
                        <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>%</th>
                        <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Frames</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>0%</td>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>19</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>7%</td>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>18</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>14%</td>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>17</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>22%</td>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>16</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>32%</td>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>15</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>48%</td>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>14</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>68%</td>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>13</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>99%</td>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>12</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>152%</td>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>11</td>
                    </tr>
                    </tbody>
                </table>

                <h3>Amazon Faster Block Rate Frames (1-handed or 2-handed sword held in one hand)</h3>
                <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                    <tr>
                        <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>%</th>
                        <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Frames</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>0%</td>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>17</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>4%</td>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>16</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>6%</td>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>15</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>11%</td>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>14</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>15%</td>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>13</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>23%</td>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>12</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>29%</td>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>11</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>40%</td>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>10</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>56%</td>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>9</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>80%</td>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>8</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>120%</td>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>7</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>200%</td>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>6</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>480%</td>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>5</td>
                    </tr>
                    </tbody>
                </table>

                <h3>Amazon Faster Block Rate Frames (any other weapon)</h3>
                <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                    <tr>
                        <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>%</th>
                        <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Frames</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>0%</td>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>5</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>13%</td>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>4</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>32%</td>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>3</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>86%</td>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>2</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>600%</td>
                        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>1</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        ),
    },
        {
            name: "Assassin",
            image: assaImg,
            content: (
                <div>
                    <h3>Assassin FHR</h3>
                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                        <tr>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>%</th>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Frames</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>0%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>9</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>7%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>8</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>15%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>7</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>27%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>6</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>48%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>5</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>86%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>4</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>200%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>3</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>4680%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>2</td>
                        </tr>
                        </tbody>
                    </table>

                    <h3>Assassin FCR</h3>
                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                        <tr>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>%</th>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Frames</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>0%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>16</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>8%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>15</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>16%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>14</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>27%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>13</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>42%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>12</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>65%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>11</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>102%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>10</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>174%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>9</td>
                        </tr>
                        </tbody>
                    </table>

                    <h3>Assassin FBR</h3>
                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                        <tr>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>%</th>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Frames</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>0%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>5</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>13%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>4</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>32%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>3</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>86%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>2</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>600%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>1</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            ),
        }, {
            name: "Necromancer",
            image: necImg,
            content: (
                <div>
                    <h3>Necromancer FHR</h3>
                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                        <tr>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>%</th>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Frames</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>0%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>13</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>5%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>12</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>10%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>11</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>16%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>10</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>26%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>9</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>39%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>8</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>56%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>7</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>86%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>6</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>152%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>5</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>377%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>4</td>
                        </tr>
                        </tbody>
                    </table>

                    <h3>Necromancer FCR (Necromancer)</h3>
                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                        <tr>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>%</th>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Frames</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>0%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>15</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>9%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>14</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>18%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>13</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>30%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>12</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>48%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>11</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>75%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>10</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>125%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>9</td>
                        </tr>
                        </tbody>
                    </table>

                    <h3>Necromancer FCR (Necromancer - Vampire)</h3>
                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                        <tr>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>%</th>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Frames</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>0%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>23</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>6%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>22</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>11%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>21</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>18%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>20</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>24%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>19</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>35%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>18</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>48%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>17</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>65%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>16</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>86%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>15</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>120%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>14</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>180%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>13</td>
                        </tr>
                        </tbody>
                    </table>

                    <h3>Necromancer FBR</h3>
                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                        <tr>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>%</th>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Frames</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>0%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>11</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>6%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>10</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>13%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>9</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>20%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>8</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>32%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>7</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>52%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>6</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>86%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>5</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>174%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>4</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>600%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>3</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            ),
        }, {
            name: "Barbarian",
            image: barbImg,
            content: (
                <div>
                    <h3>Barbarian FHR</h3>
                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                        <tr>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>%</th>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Frames</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>0%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>9</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>7%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>8</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>15%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>7</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>27%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>6</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>48%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>5</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>86%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>4</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>200%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>3</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>4680%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>2</td>
                        </tr>
                        </tbody>
                    </table>

                    <h3>Barbarian FCR</h3>
                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                        <tr>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>%</th>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Frames</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>0%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>13</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>9%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>12</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>20%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>11</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>37%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>10</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>63%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>9</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>105%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>8</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>200%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>7</td>
                        </tr>
                        </tbody>
                    </table>

                    <h3>Barbarian FBR</h3>
                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                        <tr>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>%</th>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Frames</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>0%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>7</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>9%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>6</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>20%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>5</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>42%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>4</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>86%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>3</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>280%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>2</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            ),
        },
        {
            name: "Paladin",
            image: palaImg,
            content: (
                <div>
                    <h3>Paladin FHR</h3>
                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                        <tr>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>%</th>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Frames</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>0%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>9</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>7%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>8</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>15%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>7</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>27%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>6</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>48%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>5</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>86%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>4</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>200%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>3</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>4680%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>2</td>
                        </tr>
                        </tbody>
                    </table>

                    <h3>Paladin FCR</h3>
                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                        <tr>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>%</th>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Frames</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>0%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>15</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>9%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>14</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>18%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>13</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>30%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>12</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>48%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>11</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>75%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>10</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>125%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>9</td>
                        </tr>
                        </tbody>
                    </table>

                    <h3>Paladin FBR</h3>
                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                        <tr>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Condition</th>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>%</th>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Frames</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td rowSpan={2} style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Paladin (With Holy shield)</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>0%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>2</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>86%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>1</td>
                        </tr>
                        <tr>
                            <td rowSpan={5} style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Paladin (Without Holy shield)</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>0%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>5</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>13%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>4</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>32%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>3</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>86%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>2</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>600%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>1</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            ),
        },
        {
            name: "Sorceress",
            image: sosoImg,
            content: (
                <div>
                    <h3>Sorceress FHR</h3>
                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                        <tr>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>%</th>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Frames</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>0%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>15</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>5%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>14</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>9%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>13</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>14%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>12</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>20%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>11</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>30%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>10</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>42%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>9</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>60%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>8</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>86%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>7</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>142%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>6</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>280%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>5</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>1480%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>4</td>
                        </tr>
                        </tbody>
                    </table>

                    <h3>Sorceress FCR</h3>
                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                        <tr>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>%</th>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Frames</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>0%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>13</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>9%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>12</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>20%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>11</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>37%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>10</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>63%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>9</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>105%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>8</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>200%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>7</td>
                        </tr>
                        </tbody>
                    </table>

                    <h3>Sorceress FBR</h3>
                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                        <tr>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>%</th>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Frames</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>0%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>9</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>7%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>8</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>15%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>7</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>27%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>6</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>48%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>5</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>86%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>4</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>200%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>3</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>4680%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>2</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            ),
        },
        {
            name: "Druid",
            image: duduImg,
            content: (
                <div>
                    <h3>Druid FHR</h3>
                    <p>Druid (*) hit by a 1-handed weapon or 2-handed sword held in one hand</p>
                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                        <tr>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>%</th>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Frames</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>0%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>14</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>3%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>13</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>7%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>12</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>13%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>11</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>19%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>10</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>29%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>9</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>42%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>8</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>63%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>7</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>99%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>6</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>174%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>5</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>456%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>4</td>
                        </tr>
                        </tbody>
                    </table>

                    <p>Druid hit by any other weapon</p>
                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                        <tr>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>%</th>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Frames</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>0%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>13</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>5%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>12</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>10%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>11</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>16%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>10</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>26%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>9</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>39%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>8</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>56%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>7</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>86%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>6</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>152%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>5</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>377%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>4</td>
                        </tr>
                        </tbody>
                    </table>

                    <h3>Druid FCR</h3>
                    <p>Druid (human)</p>
                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                        <tr>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>%</th>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Frames</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>0%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>18</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>4%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>17</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>10%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>16</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>19%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>15</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>30%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>14</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>46%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>13</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>68%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>12</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>99%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>11</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>163%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>10</td>
                        </tr>
                        </tbody>
                    </table>

                    <p>Druid (wolf)</p>
                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                        <tr>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>%</th>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Frames</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>0%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>16</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>6%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>15</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>14%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>14</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>26%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>13</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>40%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>12</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>60%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>11</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>95%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>10</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>157%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>9</td>
                        </tr>
                        </tbody>
                    </table>

                    <p>Druid (bear)</p>
                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                        <tr>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>%</th>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Frames</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>0%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>16</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>7%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>15</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>15%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>14</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>26%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>13</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>40%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>12</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>63%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>11</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>99%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>10</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>163%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>9</td>
                        </tr>
                        </tbody>
                    </table>

                    <h3>Druid FBR</h3>
                    <p>Druid</p>
                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                        <tr>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>%</th>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Frames</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>0%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>11</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>6%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>10</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>13%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>9</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>20%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>8</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>32%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>7</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>52%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>6</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>86%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>5</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>174%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>4</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>600%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>3</td>
                        </tr>
                        </tbody>
                    </table>

                    <p>Druid (Wolf)</p>
                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                        <tr>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>%</th>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Frames</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>0%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>9</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>7%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>8</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>15%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>7</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>27%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>6</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>48%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>5</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>86%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>4</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>200%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>3</td>
                        </tr>
                        </tbody>
                    </table>

                    <p>Druid (Bear)</p>
                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                        <tr>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>%</th>
                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Frames</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>0%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>12</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>5%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>11</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>10%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>10</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>16%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>9</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>27%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>8</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>40%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>7</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>65%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>6</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>109%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>5</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>223%</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>4</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            )
        }]);
    const [showSavedSearches, setShowSavedSearches] = useState(parseInt(sss));
    const [showRarities, setShowRarities] = useState(parseInt(sr));
    const [showCategories, setShowCategories] = useState(parseInt(sc));
    const [showStats, setShowStats] = useState(parseInt(ss));
    const [itemData, setItemData] = useState([]);
    const [statFilters, setStatFilters] = useState([]);
    const [searchTermFilters, setSearchTermFilters] = useState([]);
    const [savedSearches, setSavedSearches] = useState([]);
    const [loadingData, setLoadingData] = useState(true);
    const [detailModal, setDetailModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [createdOffers, setCreatedOffers] = useState([]);
    const [pendingOffers, setPendingOffers] = useState([]); //these offers must be accepted/denied from the user
    const [currentTZ, setCurrentTZ] = useState({
        "last_terror_zone_name_list": "Loading data..",
        "last_terror_zone_act": "Loading data.."
    });

    let baseS = ""
    let discordIDS = localStorage.getItem("discord_id")
    if (discordIDS) {
        baseS = discordIDS
    }
    const [discordID, setDiscordID] = useState(baseS);

    async function getPSSStats() {
        let url = config.backend_base_url + 'pss/stats/all'
        await axios.get(url, {headers: {'session-token': sessionToken}}).then(response => {
            if (response.data != null && response.data.length > 0) {
                setStatFilters(response.data)
            } else {
                setStatFilters([])
            }
        }).catch(error => {
            console.log("Error ========>", error);
            toast.error("Error getting stats")
        })
    }

    async function getPendingOffers() {
        let pendingOfs = []
        let url = config.backend_base_url + 'pss/admin/offers'
        await axios.get(url, {headers: {'session-token': sessionToken}}).then(response => {
            if (response.data != null && response.data.length > 0) {
                pendingOfs = pendingOfs.concat(response.data)
            }
        }).catch(error => {
            console.log("Error ========>", error);
        })

        url = config.backend_base_url + 'pss/staff/offers'
        await axios.get(url, {headers: {'session-token': sessionToken}}).then(response => {
            if (response.data != null && response.data.length > 0) {
                pendingOfs = pendingOfs.concat(response.data)
            }
        }).catch(error => {
            console.log("Error ========>", error);
        })

        setPendingOffers(pendingOfs)
    }

    function getCreatedOffers() {
        let url = config.backend_base_url + 'pss/offers'
        axios.post(url, {
            "offer_ids": JSON.parse(localStorage.getItem("created_offers")),
        }, {headers: {'session-token': sessionToken}}).then(response => {

            if (response.data) {
                let i = 0;
                while (i < response.data.length) {
                    if (response.data[i].status === "accepted") {
                        // const cachedOffers = JSON.parse(localStorage.getItem("cached_offers"))
                        // let newData = []
                        // if (cachedOffers) {
                        //  newData = cachedOffers
                        // }
                        // if (newData.includes(response.data[i].id)) {
                        //  continue
                        // }
                        // newData.push(response.data[i].id)
                        // localStorage.setItem("cached_offers", JSON.stringify(newData))
                        toast.success("Your offer on " + response.data[i].item_name + " got accepted!")
                    }
                    i++;
                }

                setCreatedOffers(response.data)
            } else {
                setCreatedOffers([])
            }
            console.log("Success ========>", response.data);
        }).catch(error => {
            console.log("Error ========>", error);
        })
    }

    function acceptOfferFunction(offerID) {
        let url = config.backend_base_url + 'pss/o/accept'
        axios.post(url, {
            "offer_id": offerID
        }, {headers: {'session-token': sessionToken}}).then(response => {
            getPendingOffers()
            console.log("Success ========>", response.data);
        }).catch(error => {
            console.log("Error ========>", error);
        })
    }

    function declineOfferFunction(offerID) {
        let url = config.backend_base_url + 'pss/o/decline'
        axios.post(url, {
            "offer_id": offerID
        }, {headers: {'session-token': sessionToken}}).then(response => {
            getPendingOffers()
            console.log("Success ========>", response.data);
        }).catch(error => {
            console.log("Error ========>", error);
        })
    }

    function clickItemDetails(event, item) {
        event.preventDefault()
        setSelectedItem(item)
        setDetailModal(true)
    }

    let sendDiscordIDUpdate = function (discordID) {
        axios.post(config.backend_base_url + "user/update/discordid", {
            "discordid": discordID,
        }, {headers: {'session-token': sessionToken}}).then(response => {
            console.log("Success ========>", response.data);
            toast.success("Discord ID set for your account")
        }).catch(error => {
            console.log("Error ========>", error);
            toast.error("Something went wrong, please try again.")
        })
    }

    function setDiscordIDFunction(newVal) {
        setDiscordID(newVal)
        localStorage.setItem("discord_id", newVal)
    }

    function priceOffer(event, itemID, list) {
        event.preventDefault()
        if (isNaN(itemID)) {
            toast.error("Faulty ID")
            return
        }
        if (list) {
            const price = window.prompt("Enter the new price in $", "");
            if (isNaN(price) || !price) {
                return
            }
            if (list) {
                //list this on d2r-shop.com
                let url = config.backend_base_url + 'd2rs/listing/update'
                if (price === "" || price === null) {
                    return
                }
                toast.success("Listing/Updating product on d2r-shop.com, this can take a moment. Please wait for the next notification...")

                axios.post(url, {
                    "item_id": itemID,
                    "new_price": price,
                }, {headers: {'session-token': sessionToken}}).then(response => {
                    if (response.status) {
                        toast.success("Successfully listed/updated item on d2r-shop.com! It can take a few minutes to be visible.")
                    }
                }).catch(error => {
                    console.log("Error ========>", error);
                    toast.error(error.message)
                })
            }
        } else {
            let url = config.backend_base_url + 'pss/offer'
            const offer = window.prompt("Enter your offer in $", "");
            if (isNaN(offer) || !offer) {
                return
            }
            if (discordID === "") {
                toast.success("Did you know? You can enter your Discord ID in the Offers tab to get notified on Discord about updates on your offers.")
            }
            axios.post(url, {
                "item_id": itemID,
                "offer": offer,
                "discordid": discordID,
            }, {headers: {'session-token': sessionToken}}).then(response => {
                if (response.status === 200) {
                    const createdOffers = JSON.parse(localStorage.getItem("created_offers"))
                    let newData = []
                    if (createdOffers) {
                        newData = createdOffers
                    }
                    newData.push(response.data)
                    localStorage.setItem("created_offers", JSON.stringify(newData))
                    getCreatedOffers()
                    toast.success("Offer was made. You will receive a notification on Discord with purchase instructions if your offer was accepted.")
                } else {
                    toast.error("Error occured.")
                }
            }).catch(error => {
                console.log("Error ========>", error);
                console.log(error);
                toast.error("Error occured.")
            })
        }
    }

    async function getPSSSearchTerms() {
        let url = config.backend_base_url + 'pss/terms/all'
        await axios.get(url, {headers: {'session-token': sessionToken}}).then(response => {
            if (response.data != null && response.data.length > 0) {
                setSearchTermFilters(response.data)
            } else {
                setSearchTermFilters([])
            }
        }).catch(error => {
            console.log("Error ========>", error);
            toast.error("Error getting terms.")
        })
    }

    async function getTz() {
        let url = config.backend_base_url + 'tz'
        await axios.get(url, {}).then(response => {
            setCurrentTZ(response.data)
        }).catch(error => {
            console.log("Error ========>", error);
            toast.error("Error getting tz.")
        })
    }

    function getItemsSearch() {
        setIsTyping(false)
        let url = config.backend_base_url + 'public/pss/search'
        axios.post(url, {
            "search_term": searchTerm,
        }, {headers: {'session-token': sessionToken}}).then(response => {
            if (response.data != null && response.data.length > 0) {
                toast.success("Search successful [" + response.data.length + "]")
                setItemData(response.data)
            } else {
                toast.error("Search found no matches")
                setItemData([])
            }
        }).catch(error => {
            console.log("Error ========>", error);
            toast.error("Error searching items")
        })
        const params = new URLSearchParams(location.search);
        if (searchTerm !== "") {
            params.set('term', searchTerm);
            const newUrl = `${location.pathname}?${params.toString()}`;
            window.history.pushState({}, '', newUrl);
        }
    }

    function getItemsSearchByEnter(event) {
        if (!isTyping) {
            setIsTyping(true)
        }
        if (event.key === "Enter") {
            getItemsSearch();
        }
    }

    function onStatChange(event, values) {
        event.preventDefault()
        if (!values) {
            return
        }
        let newTerm = searchTerm + "," + values.label
        if (values.label !== "unid" && values.label !== "eth") {
            newTerm += "=" + minVal + "-" + maxVal
        }
        setSearchTerm(newTerm.replaceAll(",,", ","))
    }

    let handleSSToggle = function (event) {
        if (event.target.checked === true) {
            setShowSavedSearches(1)
            localStorage.setItem("showSavedSearches", 1)
        } else {
            setShowSavedSearches(0)
            localStorage.setItem("showSavedSearches", 0)
        }
    }
    let handleShowRarityToggle = function (event) {
        if (event.target.checked === true) {
            setShowRarities(1)
            localStorage.setItem("showRarities", 1)
        } else {
            setShowRarities(0)
            localStorage.setItem("showRarities", 0)
        }
    }
    let handleShowCategories = function (event) {
        if (event.target.checked === true) {
            setShowCategories(1)
            localStorage.setItem("showCategories", 1)
        } else {
            setShowCategories(0)
            localStorage.setItem("showCategories", 0)
        }
    }
    let handleShowStatsToggle = function (event) {
        if (event.target.checked === true) {
            setShowStats(1)
            localStorage.setItem("showStats", 1)
        } else {
            setShowStats(0)
            localStorage.setItem("showStats", 0)
        }
    }

    function clearSearch(event) {
        event.preventDefault()
        setSearchTerm(baseSearchTerm)
        setMinVal(1)
        setMaxVal(9999)
    }

    function searchStepBack(event) {
        event.preventDefault()
        let arr = searchTerm.split(",")
        if (arr.length > 0) {
            arr.pop()
            setSearchTerm(arr.join(","))
        }
    }

    function setNewRarTerm(event, newRar) {
        event.preventDefault()
        let newTerm = searchTerm + "," + newRar
        let arr = searchTerm.split(",")

        if (searchTerm.includes(newRar)) {
            newTerm = searchTerm.replaceAll(newRar, "")
        } else {
            if (searchTerm.includes(",")) {

                let filtered = arr.filter(withoutRarTerm)
                newTerm = filtered.join(",") + newRar
            }
        }

        setSearchTerm(newTerm.replaceAll(",,", ","))
    }

    function withoutRarTerm(term) {
        let bools = baseRarities.some(
            value => {
                let comp1 = value.search_term.replaceAll(",", "")
                let comp2 = term.replaceAll(",", "")
                return comp1 === comp2
            });
        return !bools;
    }

    function setNewCategory(event, newCat) {
        event.preventDefault()
        let newTerm = newCat
        let arr = searchTerm.split(",")
        if (newCat === searchTerm.split(",")[0]) {
            //we need to deselect this
            arr.shift()
            newTerm = arr.join(",")
        } else {
            //we select this
            if (searchTerm.includes(",")) {
                let filtered = arr.filter(withoutCatTerm)
                if (filtered.length > 0) {
                    newTerm += "," + filtered.join(",")
                }
            }
        }

        setSearchTerm(newTerm.replaceAll(",,", ","))
    }

    function withoutCatTerm(term) {
        return !searchTermFilters.some(
            value => {
                return value.label === term
            });
    }

    function saveAsSavedSearch(event) {
        event.preventDefault()
        let url = config.backend_base_url + 'ml/search/add'
        const searchName = window.prompt("Enter the name of this search", "Search Name");
        if (!searchName) {
            return
        }
        axios.post(url, {
            "search_term": searchTerm,
            "search_name": searchName
        }, {headers: {'session-token': sessionToken}}).then(response => {
            if (response.status === 200) {
                toast.success("Adding Search successfull.")
                getSavedSearches();
            } else {
                toast.error("Adding Search failed")
            }
        }).catch(error => {
            console.log("Error ========>", error);
            toast.error("Error adding saved search")
        })
    }

    function handleSuggestionChange(newVal) {
        let newTerm = searchTerm
        if (searchTerm.includes(newVal)) {
            newTerm = searchTerm.replaceAll(newVal, "")
        } else {
            newTerm = searchTerm + newVal
        }
        setSearchTerm(newTerm.replaceAll(",,", ""))
    }

    function setMaxValFunction(newVal) {
        if (isNaN(newVal) || !newVal) {
            return
        }
        setMaxVal(newVal)
    }

    function setMinValFunction(newVal) {
        if (isNaN(newVal) || !newVal) {
            return
        }
        setMinVal(newVal)
    }

    function getUserData() {
        if (!sessionToken) {
            return
        } else {
            let url = config.backend_base_url + 'user/type'
            axios.get(url, {headers: {'session-token': sessionToken}}).then(response => {
                if (response.data != null) {
                    if (response.data["discordid"]) {
                        localStorage.setItem("discord_id", response.data["discordid"])
                        setDiscordID(response.data["discordid"])
                    }
                }
            }).catch(error => {
                console.log("Error ========>", error);
                toast.error("Error getting user data")
            })
        }
    }

    function deleteSavedSearch(event) {
        event.preventDefault()
        const searchName = window.prompt("Enter the name of the Saved Search you want to delete", "Search Name");
        if (!searchName) {
            return
        }
        let url = config.backend_base_url + 'ml/search/title/delete'
        axios.post(url, {
            "name": searchName,
        }, {headers: {'session-token': sessionToken}}).then(response => {
            if (response.status === 200) {
                toast.success("Removing Saved search successfull.")
                getSavedSearches()
            } else {
                toast.error("Deleting Search failed")
            }
        }).catch(error => {
            console.log("Error ========>", error);
            toast.error("Error deleting saved search")
        })
    }

    async function getSavedSearches() {
        let url = config.backend_base_url + 'ml/search/mine'
        if (!sessionToken) {
            return
        }
        await axios.get(url, {headers: {'session-token': sessionToken}}).then(response => {
            if (response.data != null && response.data.length > 0) {
                setSavedSearches(response.data)
            } else {
                setSavedSearches([])
            }
        }).catch(error => {
            console.log("Error ========>", error);
            toast.error("Error getting saved searches")
        })
    }

    const [headerHeight, setHeaderHeight] = useState(0);
    const headerRef = useRef(null);


    useEffect(() => {
        const updateHeaderHeight = () => {
            if (headerRef.current) {
                const headerComputedStyle = window.getComputedStyle(headerRef.current);
                const headerHeight = headerRef.current.offsetHeight;
                const headerPaddingTop = parseInt(headerComputedStyle.paddingTop, 10);
                const headerPaddingBottom = parseInt(headerComputedStyle.paddingBottom, 10);

                // Calculate total height including padding
                const totalHeight = headerHeight + (headerPaddingTop + headerPaddingBottom) *2;

                setHeaderHeight(totalHeight);
            }
        };

        updateHeaderHeight(); // Initial call to set height

        const intervalId = setInterval(() => {
            updateHeaderHeight();
        }, 1000); // Run every second

        // Stop updating after 10 seconds
        setTimeout(() => {
            clearInterval(intervalId);
        }, 10000);

        window.addEventListener("resize", updateHeaderHeight); // Update height on resize

        return () => {
            clearInterval(intervalId); // Cleanup interval on unmount
            window.removeEventListener("resize", updateHeaderHeight); // Cleanup listener on unmount
        };
    }, []);

    const handleChange = () => {
    };

    if (loadingData) {
        getUserData()
        getItemsSearch()
        getPSSStats()
        getPSSSearchTerms()
        getSavedSearches()
        getPendingOffers()
        getCreatedOffers()
        getTz()
        setLoadingData(false)
    }


    return (
        <div>
            <Header
                color="dark"
                routes={dashboardRoutes}
                brand="D2RS"
                rightLinks={<HeaderLinks/>}
                fixed
                ref={headerRef}
                {...rest}
            />

            <Parallax small filter headerHeight={headerHeight}>
                <div className={classes.container}>
                    <Dialog
                        disableEnforceFocus
                        classes={{
                            root: classes.center,
                            paper: classes.modal,
                        }}
                        open={detailModal}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={() => setDetailModal(false)}
                        aria-labelledby="classic-modal-slide-title"
                        aria-describedby="classic-modal-slide-description"
                    >
                        <DialogTitle
                            id="classic-modal-slide-title"
                            disableTypography
                            className={classes.modalHeader}
                        >
                            <IconButton
                                className={classes.modalCloseButton}
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                onClick={() => setDetailModal(false)}
                            >
                                <Close className={classes.modalClose}/>
                            </IconButton>
                        </DialogTitle>
                        <DialogContent
                            id="classic-modal-slide-description"
                            className={classes.modalBody}
                            style={{textAlign: "center"}}
                        >
                            <GridContainer justify="center" spacing={1}>
                                <GridItem xs={12} sm={12} md={12}>
                                    <h3>{selectedItem.name} [{selectedItem.context}] ({selectedItem.status})</h3>
                                    <br/>
                                    <h2>{selectedItem.d2rshopproductlink !== "" && selectedItem.fiat_price ? (
                                        <b>{selectedItem.fiat_price.replaceAll(".00", "")}$</b>) : (<div></div>)}</h2>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <img src={"https://d2r-member.com/image/" + selectedItem.image_path}
                                         style={{display: "block", marginLeft: "auto", marginRight: "auto"}}
                                         alt={selectedItem.name}/>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    {selectedItem.d2rshopproductlink !== "" && selectedItem.fiat_price ? (
                                        <Button color="primary" style={{width: "100%"}}
                                                href={selectedItem.d2rshopproductlink}
                                                target="_blank">
                                            Visit Vendor ({selectedItem.fiat_price.replaceAll(".00", "")}$)
                                        </Button>) : (<div></div>)}
                                    <Button color="grey" style={{width: "100%"}}
                                            onClick={() => priceOffer(event, selectedItem.id, false)}>
                                        Make $ price offer
                                    </Button>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    {/*todo: make it so only users with a certain flag can do this!!*/}
                                    {sessionToken ? (
                                        <div><h2>Advanced Options</h2><br/>
                                            <GridItem xs={12} sm={12} md={12}>
                                                <Button color="primary" style={{width: "100%"}}
                                                        onClick={() => priceOffer(event, selectedItem.id, true)}>
                                                    {selectedItem.d2rshopproductlink === "" ? (
                                                        <h>List this Item</h>) : (<h>Update Listing Price</h>)} &nbsp;on
                                                    d2r-shop.com
                                                </Button>
                                            </GridItem>
                                        </div>
                                    ) : (
                                        <div></div>)}
                                </GridItem>
                            </GridContainer>
                        </DialogContent>
                    </Dialog>
                    {/*<GridContainer>*/}
                    {/* <GridItem xs={12} sm={12} md={6}>*/}
                    {/*  <h1>Diablo 2 Rare and Craft Search</h1>*/}
                    {/* </GridItem>*/}
                    {/*</GridContainer>*/}
                </div>
            </Parallax>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container} style={{
                    width: "80vw",
                    position: "relative",
                    maxWidth: "80vw",
                    left: "60%",
                    marginLeft: "-50vw",
                    textAlign: "center",
                    color: "black"
                }}>
                    <NavPills
                        queryKey={"t"}
                        alignCenter
                        color="primary"
                        onChange={handleChange}
                        tabs={[
                            {
                                tabName: "search",
                                tabButton: (
                                    <span style={{"display": "block"}}>
             Item Search <img style={{width: '36px'}} src={searchImg} alt="search"/>
            </span>
                                ),
                                tabContent: (
                                    <span>
                 <CustomInput
                     labelText="Search Term..."
                     id="searchTerm"
                     value={searchTerm}
                     formControlProps={{
                         fullWidth: true
                     }}
                     onKeyDown={getItemsSearchByEnter}
                     onChange={(params) => {
                         setSearchTerm(params.target.value)
                     }}
                     inputProps={{
                         type: "text",
                         endAdornment: (
                             <InputAdornment position="end">
                                 <Search className={classes.inputIconsColor}/>
                             </InputAdornment>
                         ),
                         autoComplete: "off",
                     }}
                 />
          <Button color="primary" size="sm"
                  onClick={getItemsSearch}>Get Search Results ({itemData.length})</Button>
                <Button
                    color="warning"
                    size="sm"
                    onClick={clearSearch}>Clear Search</Button>
                 <Button
                     color="warning"
                     size="sm"
                     onClick={searchStepBack}>Go One Search Step Back</Button>
                <GridItem xs={12} sm={12}>

                        </GridItem>

                                                                                                                          <GridContainer>
                                                                                                                           {sessionToken ? (
                                                                                                                               <GridItem
                                                                                                                                   xs={12}
                                                                                                                                   sm={4}
                                                                                                                                   md={3}>
                                                                                                                                   <FormGroup>
                                                                                                                                       <FormControlLabel
                                                                                                                                           control={
                                                                                                                                               <Checkbox
                                                                                                                                                   checked={showSavedSearches === 1}
                                                                                                                                                   id={"sstoggle"}
                                                                                                                                                   onChange={handleSSToggle}
                                                                                                                                                   inputProps={{'aria-label': 'controlled'}}
                                                                                                                                               />}
                                                                                                                                           label="Show Saved Searches"
                                                                                                                                           style={{marginTop: "7px"}}/>
                                                                                                                                   </FormGroup></GridItem>) : (
                                                                                                                               <h></h>)}
                                                                                                                              <GridItem
                                                                                                                                  xs={12}
                                                                                                                                  sm={4}
                                                                                                                                  md={3}>
                                                                                                                            <FormGroup>
                                                                                                                             <FormControlLabel
                                                                                                                                 control={
                                                                                                                                     <Checkbox
                                                                                                                                         checked={showRarities === 1}
                                                                                                                                         id={"rartoggle"}
                                                                                                                                         onChange={handleShowRarityToggle}
                                                                                                                                         inputProps={{'aria-label': 'controlled'}}
                                                                                                                                     />}
                                                                                                                                 label="Show Rarity Filters"
                                                                                                                                 style={{marginTop: "7px"}}/>
                                                                                                                            </FormGroup>
                                                                                                                           </GridItem>
                                                                                                                           <GridItem
                                                                                                                               xs={12}
                                                                                                                               sm={4}
                                                                                                                               md={3}>
                                                                                                                            <FormGroup>
                                                                                                                             <FormControlLabel
                                                                                                                                 control={
                                                                                                                                     <Checkbox
                                                                                                                                         checked={showCategories === 1}
                                                                                                                                         id={"cattoggle"}
                                                                                                                                         onChange={handleShowCategories}
                                                                                                                                         inputProps={{'aria-label': 'controlled'}}
                                                                                                                                     />}
                                                                                                                                 label="Show Category Filters"
                                                                                                                                 style={{marginTop: "7px"}}/>
                                                                                                                            </FormGroup>
                                                                                                                           </GridItem>
                                                                                                                           <GridItem
                                                                                                                               xs={12}
                                                                                                                               sm={4}
                                                                                                                               md={3}>
                                                                                                                            <FormGroup>
                                                                                                                             <FormControlLabel
                                                                                                                                 control={
                                                                                                                                     <Checkbox
                                                                                                                                         checked={showStats === 1}
                                                                                                                                         id={"stattoggle"}
                                                                                                                                         onChange={handleShowStatsToggle}
                                                                                                                                         inputProps={{'aria-label': 'controlled'}}
                                                                                                                                     />}
                                                                                                                                 label="Show Stat Filters"
                                                                                                                                 style={{marginTop: "7px"}}/>
                                                                                                                            </FormGroup>
                                                                                                                           </GridItem>

                                                                                                                             <GridItem
                                                                                                                                 xs={12}
                                                                                                                                 sm={12}
                                                                                                                                 md={12}>
                                                                                                                           {sessionToken && showSavedSearches === 1 ? (
                                                                                                                               <div>
                                                                                                                                   {savedSearches.map((st, key) =>
                                                                                                                                       <Button
                                                                                                                                           color="#385170"
                                                                                                                                           key={key}
                                                                                                                                           size="sm"
                                                                                                                                           onClick={() => setSearchTerm(st.search_term)}>{st.search_name} ({st.search_count})</Button>
                                                                                                                                   )}
                                                                                                                                   <br/>
                                                                                                                                   <Button
                                                                                                                                       color="primary"
                                                                                                                                       size="sm"
                                                                                                                                       onClick={saveAsSavedSearch}>Save
                                                                                                                                       current
                                                                                                                                       term
                                                                                                                                       as
                                                                                                                                       Saved
                                                                                                                                       Search</Button>
                                                                                                                                   <Button
                                                                                                                                       color="warning"
                                                                                                                                       size="sm"
                                                                                                                                       onClick={deleteSavedSearch}>Delete
                                                                                                                                       a
                                                                                                                                       Saved
                                                                                                                                       Search</Button>
                                                                                                                               </div>) : (
                                                                                                                               <div></div>)}
                                                                                                                              </GridItem>
                                                                                                                           <GridItem
                                                                                                                               xs={12}
                                                                                                                               sm={12}
                                                                                                                               md={12}>
                                                                                                                            {showRarities === 1 ? (
                                                                                                                                <div>
                                                                                                                                    {baseRarities.map((sugg, key) =>
                                                                                                                                        <Button
                                                                                                                                            color={searchTerm.includes(sugg.search_term) ? ("danger") : ("grey")}
                                                                                                                                            key={key}
                                                                                                                                            size="sm"
                                                                                                                                            onClick={() => setNewRarTerm(event, sugg.search_term)}>{searchTerm.includes(sugg.search_term) ? (
                                                                                                                                            <Close></Close>) : ("")}{sugg.title}</Button>
                                                                                                                                    )}
                                                                                                                                </div>) : (
                                                                                                                                <div></div>)}
                                                                                                                             </GridItem>
                                                                                                                             <GridItem
                                                                                                                                 xs={12}
                                                                                                                                 sm={12}
                                                                                                                                 md={12}>
                                                                                                                           {showCategories === 1 ? (
                                                                                                                               <div>
                                                                                                                                   {searchTermFilters.map((st, key) =>
                                                                                                                                       <Button
                                                                                                                                           // color={st.label.includes((searchTerm.split(",")[0]) && searchTerm.includes(",")) || st.label === searchTerm ? ("danger"):("grey")}
                                                                                                                                           color={(st.label.includes(searchTerm) && searchTerm !== "" && !searchTerm.includes(",")) || (st.label.includes(searchTerm.split(",")[0]) && searchTerm.split(",")[0] !== "" && searchTerm.includes(",")) || st.label === searchTerm ? ("danger") : ("grey")}
                                                                                                                                           key={key}
                                                                                                                                           size="sm"
                                                                                                                                           onClick={() => setNewCategory(event, st.label)}>{st.label === searchTerm.split(",")[0] ? (
                                                                                                                                           <Close></Close>) : ("")}{st.label}</Button>
                                                                                                                                   )}
                                                                                                                               </div>) : (
                                                                                                                               <div></div>)}
                                                                                                                             </GridItem>
                                                                                                                             <GridItem
                                                                                                                                 xs={12}
                                                                                                                                 sm={12}
                                                                                                                                 md={12}>
                                                                                                                            {showStats === 1 ? (
                                                                                                                                <GridContainer
                                                                                                                                    justify="center">
                                                                                                                                    <GridItem
                                                                                                                                        xs={6}
                                                                                                                                        sm={12}
                                                                                                                                        md={3}>
                                                                                                                                        <CustomInput
                                                                                                                                            labelText="Stat Min Value"
                                                                                                                                            id="minValue"
                                                                                                                                            value={minVal}
                                                                                                                                            formControlProps={{
                                                                                                                                                fullWidth: true,
                                                                                                                                            }}
                                                                                                                                            onChange={(params) => {
                                                                                                                                                setMinValFunction(params.target.value)
                                                                                                                                            }}
                                                                                                                                            inputProps={{
                                                                                                                                                type: "number",
                                                                                                                                            }}
                                                                                                                                        />
                                                                                                                                    </GridItem>
                                                                                                                                    <GridItem
                                                                                                                                        xs={6}
                                                                                                                                        sm={6}
                                                                                                                                        md={3}>
                                                                                                                                        <CustomInput
                                                                                                                                            labelText="Stat Max Value"
                                                                                                                                            id="maxValue"
                                                                                                                                            value={maxVal}
                                                                                                                                            formControlProps={{
                                                                                                                                                fullWidth: true,
                                                                                                                                            }}
                                                                                                                                            onChange={(params) => {
                                                                                                                                                setMaxValFunction(params.target.value)
                                                                                                                                            }}
                                                                                                                                            inputProps={{
                                                                                                                                                type: "number",
                                                                                                                                            }}
                                                                                                                                        />
                                                                                                                                    </GridItem>
                                                                                                                                    <GridItem
                                                                                                                                        xs={12}
                                                                                                                                        sm={12}
                                                                                                                                        md={6}
                                                                                                                                        style={{paddingTop: 10}}>
                                                                                                                                        <Autocomplete
                                                                                                                                            id="Stat Filters"
                                                                                                                                            options={statFilters}
                                                                                                                                            renderInput={(params) =>
                                                                                                                                                <TextField {...params}
                                                                                                                                                           label="Search for a Stat Filter"/>}
                                                                                                                                            onChange={onStatChange}
                                                                                                                                        />
                                                                                                                                    </GridItem>
                                                                                                                                    <GridItem
                                                                                                                                        xs={12}
                                                                                                                                        sm={12}
                                                                                                                                        md={12}>
                                                                                                                                        {baseSuggestions.map((sugg, key) =>
                                                                                                                                            <Button
                                                                                                                                                color={searchTerm.includes(sugg.search_term) ? ("danger") : ("grey")}
                                                                                                                                                key={key}
                                                                                                                                                size="sm"
                                                                                                                                                // onClick={() =>
                                                                                                                                                //  setSearchTerm(searchTerm + sugg.search_term)}
                                                                                                                                                onClick={() =>
                                                                                                                                                    handleSuggestionChange(sugg.search_term)}
                                                                                                                                            >{searchTerm.includes(sugg.search_term) ? (
                                                                                                                                                <Close></Close>) : ("")}{sugg.title}</Button>
                                                                                                                                        )}
                                                                                                                                    </GridItem>
                                                                                                                                </GridContainer>) : (
                                                                                                                                <h></h>)}
</GridItem>



                                                             </GridContainer>
                                                             <div>
                                                              <GridContainer justify="center" spacing={1}>
                                              {itemData.map((item, key) =>
                                                  <GridItem xs={12} sm={4} md={3} key={key}
                                                            style={{textAlign: "center"}}>
                                                      <hr/>
                                                      <h7>
                                                          <b>{item.name} [{item.context}] {item.d2rshopproductlink !== "" ? (
                                                              <h>- {item.fiat_price.replaceAll(".00", "")}$</h>
                                                          ) : (<h></h>)}</b></h7>
                                                      <br/>

                                                      <img onClick={() => clickItemDetails(event, item)}
                                                           src={"https://d2r-member.com/image/" + item.image_path}
                                                           alt={item.name}
                                                           style={{
                                                               display: "block",
                                                               marginLeft: "auto",
                                                               marginRight: "auto",
                                                               maxWidth: "100%",
                                                               maxHeight: "100%"
                                                           }}
                                                      />
                                                      {item.d2rshopproductlink !== "" ? (
                                                          <h7><Button fullWidth={true} color="primary" size="sm"
                                                                      href={item.d2rshopproductlink}
                                                                      target="_blank">
                                                              Visit Vendor ({item.fiat_price.replaceAll(".00", "")}$)
                                                          </Button>
                                                          </h7>) : (<h></h>)}
                                                      <h7>
                                                          <Button color="grey" fullWidth={true} size="sm"
                                                                  onClick={() => priceOffer(event, item.id, false)}>
                                                              Make $ price offer
                                                          </Button></h7>
                                                  </GridItem>
                                              )}
                                                              </GridContainer>
                        </div>
                      </span>

                                ),
                            },
                            {
                                tabName: "offers",
                                tabButton: (
                                    <span style={{"display": "block"}}>
             Your Offers <img style={{width: '36px'}} src={d2rshopIcon} alt="offers"/>
            </span>
                                ),
                                tabContent: (
                                    <span>
          <div style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%'
          }}>

</div>
                <FormControl id="discordIDForm" fullWidth={true}>
                <CustomInput
                    labelText="Set your Discord ID"
                    id="addDiscordID"
                    value={discordID}
                    fullWidth={true}
                    onChange={(params) => {
                        setDiscordIDFunction(params.target.value)
                    }}
                    inputProps={{
                        type: "text",
                    }}
                />
                    {sessionToken ? (<Button fullWidth={true} color="primary" onClick={function () {
                        sendDiscordIDUpdate(discordID)
                    }}>
                        Save ID to my Account
                    </Button>) : (<div></div>)}
                 </FormControl>

                                        {createdOffers.length > 0 ? (<div>
                                            <hr/>
                                            <h2>Created Offers Overview</h2>
                                        </div>) : (<div></div>)}
                                        {createdOffers.map((of, key) =>
                                            <div key={key}>
                                                <hr/>
                                                <img src={of.item_image}
                                                     style={{display: "block", marginLeft: "auto", marginRight: "auto"}}
                                                     alt={of.name}/>
                                                {of.item_name} [{of.context}] - <b>{of.offer}$</b> (<i>{of.status}</i>)
                                                <br/>
                                                {of.shop_link !== "" ? (
                                                    <Button fullWidth={true} color="primary" href={of.shop_link}
                                                            target="_blank">
                                                        Visit Vendor
                                                    </Button>) : (<div></div>)}
                                            </div>
                                        )}
                                        <hr/>
                                        {pendingOffers.length > 0 ? (<div>
                                            <h2>Pending Offers Overview</h2>
                                        </div>) : (<div></div>)}
                                        {pendingOffers.map((of, key) =>
                                            <div key={key}>
                                                <hr/>
                                                {of.item_name} [{of.context}] - <b>{of.offer}$</b> (<i>{of.status}</i>)
                                                <br/>
                                                <img src={of.item_image}
                                                     style={{display: "block", marginLeft: "auto", marginRight: "auto"}}
                                                     alt={of.name}/>
                                                <Button fullWidth={true} color="warning" onClick={function () {
                                                    acceptOfferFunction(of.id)
                                                }}>
                                                    Accept Offer
                                                </Button>
                                                <Button fullWidth={true} color="warning" onClick={function () {
                                                    declineOfferFunction(of.id)
                                                }}>
                                                    Decline Offer
                                                </Button>
                                                {of.shop_link !== "" ? (
                                                    <Button fullWidth={true} color="primary" href={of.shop_link}
                                                            target="_blank">
                                                        Visit Vendor
                                                    </Button>) : (<div></div>)}
                                            </div>
                                        )}

                      </span>

                                ),
                            },
                            {
                                tabName: "tours",
                                tabButton: (
                                    <span style={{display: "block"}}>
        Tour Schedule <img style={{width: '36px'}} src={d2rsIcon} alt="d2rs"/>
                                        {tourDiscords.map((obj, index) => (
                                            <img key={index} style={{width: '36px'}} src={obj.image}
                                                 alt={obj.name}/>
                                        ))}
    </span>
                                ),
                                tabContent: (
                                    <span>
           <div className="container">

            <NavPills
                queryKey={"o"}
                alignCenter
                color="primary"
                onChange={handleChange}
                tabs={[
                    {
                        tabButton: (
                            <span style={{display: 'block'}}>
          <img style={{width: '64px'}} src={d2rsIcon} alt="d2rs"/>
        </span>
                        ),
                        tabContent: <span>
                     <table className="table" style={{
                         width: '100%',
                         borderCollapse: 'collapse',
                         margin: '20px 0',
                         fontSize: '18px',
                         textAlign: 'left'
                     }}>
      <thead>
        <tr style={{backgroundColor: '#f2f2f2', borderBottom: '2px solid #ddd'}}>
          <th style={{padding: '12px', borderBottom: '1px solid #ddd'}}>Title</th>
          <th style={{padding: '12px', borderBottom: '1px solid #ddd'}}>Day</th>
          <th style={{padding: '12px', borderBottom: '1px solid #ddd'}}>Time</th>
          <th style={{padding: '12px', borderBottom: '1px solid #ddd'}}>Ruleset</th>
            <th style={{padding: '12px', borderBottom: '1px solid #ddd'}}>Prize</th>
          <th style={{padding: '12px', borderBottom: '1px solid #ddd'}}>Organizer</th>
        </tr>
      </thead>
      <tbody>
        {tourDiscords.map((discord, discordIndex) =>
            discord.tours.map((tour, tourIndex) => (
                <tr key={`${discordIndex}-${tourIndex}`} style={{borderBottom: '1px solid #ddd'}}>
                    <td style={{padding: '12px', borderBottom: '1px solid #ddd'}}>{tour.name}</td>
                    <td style={{padding: '12px', borderBottom: '1px solid #ddd'}}>{tour.day}</td>
                    <td style={{padding: '12px', borderBottom: '1px solid #ddd'}}>{tour.time}</td>
                    <td style={{padding: '12px', borderBottom: '1px solid #ddd'}}>{tour.ruleset}</td>
                    <td style={{padding: '12px', borderBottom: '1px solid #ddd'}}>{tour.prize}</td>
                    <td style={{padding: '12px', borderBottom: '1px solid #ddd'}}>
                        <a href={discord.discord_invite} target="_blank" rel="noopener noreferrer">
                            <img
                                src={discord.image}
                                style={{width: '64px', height: '64px', borderRadius: '8px'}}
                                alt={discord.name}
                            />
                        </a>
                    </td>
                </tr>
            ))
        )}
      </tbody>
    </table>
                  </span>,
                    },
                    ...tourDiscords
                        .filter((obj) => obj.tours.length > 0)
                        .map((obj, index) => ({
                            tabButton: (
                                <span style={{display: 'block'}} key={index}>
            <img style={{width: '64px'}} src={obj.image} alt={obj.name}/>
          </span>
                            ),
                            tabContent: (
                                <span key={index}>
            {/* Customize the content here */}
                                    <table className="table" style={{
                                        width: '100%',
                                        borderCollapse: 'collapse',
                                        margin: '20px 0',
                                        fontSize: '18px',
                                        textAlign: 'left'
                                    }}>
      <thead>
        <tr style={{backgroundColor: '#f2f2f2', borderBottom: '2px solid #ddd'}}>
          <th style={{padding: '12px', borderBottom: '1px solid #ddd'}}>Title</th>
          <th style={{padding: '12px', borderBottom: '1px solid #ddd'}}>Day</th>
          <th style={{padding: '12px', borderBottom: '1px solid #ddd'}}>Time</th>
          <th style={{padding: '12px', borderBottom: '1px solid #ddd'}}>Ruleset</th>
            {/*<th style={{padding: '12px', borderBottom: '1px solid #ddd'}}>Participants</th>*/}
            <th style={{padding: '12px', borderBottom: '1px solid #ddd'}}>Prize</th>
          <th style={{padding: '12px', borderBottom: '1px solid #ddd'}}>Organizer</th>
        </tr>
      </thead>
      <tbody>
        {obj.tours.map((tour, tourIndex) => (
            <tr key={`${index}-${tourIndex}`} style={{borderBottom: '1px solid #ddd'}}>
                <td style={{padding: '12px', borderBottom: '1px solid #ddd'}}>{tour.name}</td>
                <td style={{padding: '12px', borderBottom: '1px solid #ddd'}}>{tour.day}</td>
                <td style={{padding: '12px', borderBottom: '1px solid #ddd'}}>{tour.time}</td>
                <td style={{padding: '12px', borderBottom: '1px solid #ddd'}}>{tour.ruleset}</td>
                {/*<td style={{*/}
                {/* padding: '12px',*/}
                {/* borderBottom: '1px solid #ddd'*/}
                {/*}}>{tour.participants_current}/{tour.participants_max}</td>*/}
                <td style={{padding: '12px', borderBottom: '1px solid #ddd'}}>{tour.prize}</td>
                <td style={{padding: '12px', borderBottom: '1px solid #ddd'}}>
                    <a href={obj.discord_invite} target="_blank" rel="noopener noreferrer">
                        <img
                            src={obj.image}
                            style={{width: '64px', height: '64px', borderRadius: '8px'}}
                            alt={obj.name}
                        />
                    </a>
                </td>
            </tr>
        ))
        }
      </tbody>
    </table>
          </span>
                            ),
                        })),
                    ]}>
             </NavPills>
    </div>
         </span>
                                ),
                            },
                            {
                                tabName: "streams",
                                tabButton: (
                                    <span style={{"display": "block"}}>
             Live Streams <img style={{width: '36px'}} src={broadcastImg} alt="streams"/>
            </span>
                                ),
                                tabContent: (
                                    <span>
             <div className="container">
            <NavPills
                queryKey={"o"}
                alignCenter
                color="primary"
                onChange={handleChange}
                tabs={[
                    ...tourDiscords
                        .filter((obj) => obj.tours.length > 0)
                        .map((obj, index) => ({
                            tabButton: (
                                <span style={{display: 'block'}} key={index}>
            <img style={{width: '64px'}} src={obj.image} alt={obj.name}/>
          </span>
                            ),
                            tabContent: (
                                <span key={obj.name} style={{display: 'block', width: '100%', boxSizing: 'border-box'}}>
      {obj.name === 'd2g' && (
          <a
              href="https://discord.gg/xwtATmyEkN"
              style={{color: '#142d4c', textDecoration: 'none', width: '100%', display: 'block'}}
              rel="noreferrer"
              target="_blank"
          >
              <button
                  style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: '10px 20px',
                      border: 'none',
                      backgroundColor: '#142d4c',
                      color: 'white',
                      fontSize: '16px',
                      borderRadius: '5px',
                      width: '100%',
                      cursor: 'pointer',
                      flexDirection: 'column',
                      textAlign: 'center',
                      boxSizing: 'border-box'
                  }}
              >
                  Do you want to contribute to the PvP scene?
                  <br/>
                  We are actively looking for some enthusiastic new faces to stream tours and pump some life into shit!
                  <br/>
                  Let us know on Discord if you are interested!
              </button>
          </a>
      )}
                                    <NavPills
                                        queryKey={"streamer"}
                                        alignCenter
                                        color="primary"
                                        onChange={handleChange}
                                        tabs={obj.streams.map((sObj, index2) => ({
                                            tabName: sObj,
                                            tabButton: (
                                                <span style={{display: 'block'}} key={index2}>
              {sObj}
            </span>
                                            ),
                                            tabContent: (
                                                <span key={index2}>
              <TwitchEmbedVideo
                  channel={sObj}
                  layout="video"
                  width="100%"
                  // height="780"
                  autoplay
              />
            </span>
                                            ),
                                        }))}
                                    />
    </span>
                            ),
                        })),
                ]}>
             </NavPills>
    </div>
         </span>
                                ),
                            },
                            {
                                tabButton: (
                                    <span style={{"display": "block"}}>
             Rulesets <img style={{width: '36px'}} src={scrollImg} alt="rulesets"/>
            </span>
                                ),
                                tabContent: (
                                    <span>
             <div className="container">
            <NavPills
                queryKey={"o"}
                alignCenter
                color="primary"
                onChange={handleChange}
                tabs={[
                    ...tourDiscords
                        .filter((obj) => obj.ruleSets.length > 0)
                        .map((obj, index) => ({
                            tabName: obj.name,
                            tabButton: (
                                <span style={{display: 'block'}} key={index}>
            <img style={{width: '64px'}} src={obj.image} alt={obj.name}/>
          </span>
                            ),
                            tabContent: (
                                <span key={obj.name} style={{display: 'block', width: '100%', boxSizing: 'border-box'}}>
                           <NavPills
                               queryKey={"set"}
                               alignCenter
                               color="primary"
                               onChange={handleChange}
                               tabs={obj.ruleSets.map((sObj, index2) => ({
                                   tabName: sObj.name,
                                   tabButton: (
                                       <span style={{display: 'block'}} key={index2}>
              {sObj.name}
            </span>
                                   ),
                                   tabContent: (
                                       <span key={index2}>
              {sObj.content}
            </span>
                                   ),
                               }))}
                           />
    </span>
                            ),
                        })),
                ]}>
             </NavPills>
    </div>
            </span>
                                ),
                            },
                            {
                                tabButton: (
                                    <span style={{"display": "block"}}>
             Guides & Builds <img style={{width: '36px'}} src={skillImg} alt="guides"/>
            </span>
                                ),
                                tabContent: (
                                    <span>
             <div className="container">
            <NavPills
                queryKey={"org"}
                alignCenter
                color="primary"
                onChange={handleChange}
                tabs={[
                    ...tourDiscords
                        .filter((obj) => obj.guides.length > 0)
                        .map((obj, index) => ({
                            tabName: obj.name,
                            tabButton: (
                                <span style={{display: 'block'}} key={index}>
            <img style={{width: '64px'}} src={obj.image} alt={obj.name}/>
          </span>
                            ),
                            tabContent: (
                                <span key={obj.name} style={{display: 'block', width: '100%', boxSizing: 'border-box'}}>
                           <NavPills
                               queryKey={"class"}
                               alignCenter
                               color="primary"
                               onChange={handleChange}
                               tabs={obj.guides
                                   .filter((obj) => obj.sections.length > 0)
                                   .map((sObj, index2) => ({
                                       tabName: sObj.name,
                                   tabButton: (
                                       <span style={{ display: 'block' }} key={index2}>
  {sObj.nameImage ? (
      <>
          <img style={{ height: '64px' }} src={sObj.nameImage} alt={sObj.name} />
      </>
  ) : (
      sObj.name
  )}
</span>
                                   ),
                                   tabContent: (
                                       <span key={index2}>
              <NavPills
                  queryKey={"guide"}
                  alignCenter
                  color="primary"
                  onChange={handleChange}
                  tabs={sObj.sections.map((gSection, index3) => ({
                      tabName: gSection.name,
                      tabButton: (
                          <span style={{ display: 'block' }} key={index3}>
  {gSection.nameImage ? (
      <>
          <img style={{ height: '64px' }} src={gSection.nameImage} alt={gSection.name} />
      </>
  ) : (
      gSection.name
  )}
</span>
                      ),
                      tabContent: (
                          <span key={index3}>
              {gSection.content}
            </span>
                      ),
                  }))}
              />
            </span>
                                   ),
                               }))}
                           />
    </span>
                            ),
                        })),
                ]}>
             </NavPills>
    </div>
            </span>
                                ),
                            },
                            {
                                tabButton: (
                                    <span style={{"display": "block"}}>
             Breakpoints <img style={{height: '36px'}} src={playerCharacterImg} alt="breakpoints"/>
            </span>
                                ),
                                tabContent: (
                                    <span>
           <div className="container">

            <NavPills
                queryKey={"class"}
                alignCenter
                color="primary"
                onChange={handleChange}
                tabs={[
                    {
                        tabName: "all",
                        tabButton: (
                            <span style={{display: 'block'}}>
          <img style={{width: '64px'}} src={d2rsIcon} alt="d2rs"/>
        </span>
                        ),
                        tabContent: <span>
                            <img
                                src={breakpointsImg}
                                alt="Second slide"
                                style={{
                                    marginTop: '10px'
                                }} // Makes the image half the original width and adds margin-top for spacing
                            />
                  </span>,
                    },
                    ...bpClasses
                        .map((obj, index) => ({
                            tabName: obj.name,
                            tabButton: (
                                <span style={{display: 'block'}} key={index}>
            <img style={{width: '64px'}} src={obj.image} alt={obj.name}/>
          </span>
                            ),
                            tabContent: (
                                obj.content
                            ),
                        })),
                ]}>
             </NavPills>
    </div>
         </span>
                                ),
                            },
                            {
                                tabButton: (
                                    <span style={{"display": "block"}}>
             Recipes & Misc <img style={{width: '36px'}} src={cubeImg} alt="recipes"/>
            </span>
                                ),
                                tabContent: (
                                    <span>
           <div className="container">

               <NavPills
                   queryKey={"r"}
                   alignCenter
                   color="primary"
                   onChange={handleChange}
                   tabs={[
                       {
                           tabName: "all",
                           tabButton: (
                               <span style={{display: 'block'}}>
          <img style={{width: '64px'}} src={d2rsIcon} alt="d2rs"/>
        </span>
                           ),
                           tabContent: <span>
                               {recipesAndMisc.map((entry) =>
                                   entry.content
                               )}
                  </span>,
                       },
                       ...recipesAndMisc
                           .map((obj, index) => ({
                               tabName: obj.name,
                               tabButton: (
                                   <span style={{display: 'block'}} key={index}>
            {obj.name}
          </span>
                               ),
                               tabContent: (
                                   obj.content
                               ),
                           })),
                   ]}>
             </NavPills>
    </div>
         </span>
                                ),
                            },
                            {
                                tabName: "images",
                                tabButton: (
                                    <span style={{display: 'block'}}>
                                        Image Generator 🔜<img style={{width: '36px'}} src={chestImg} alt="chest"/>
        </span>
                                ),
                                tabContent: (
                                    <span>
           <div className="container">

      <img
          src={ComingSoon}
          alt="Second slide"
          style={{
              width: '50%',
              marginTop: '10px'
          }} // Makes the image half the original width and adds margin-top for spacing
      />
    </div>
         </span>
                                ),
                            },
                            {
                                tabName: "prices",
                                tabButton: (
                                    <span style={{display: 'block'}}>
                                        Price Index 🔜<img style={{width: '36px'}} src={goldImg} alt="gold"/>
        </span>
                                ),
                                // <img style={{width: '36px'}} src={d2rsIcon} alt="d2rs"/>
                                tabContent: (
                                    <span>
           <div className="container">

      <img
          src={ComingSoon}
          alt="Second slide"
          style={{
              width: '50%',
              marginTop: '10px'
          }} // Makes the image half the original width and adds margin-top for spacing
      />
    </div>
         </span>
                                ),
                            },
                            {
                                tabButton: (
                                    <span style={{"display": "block"}}>
             Terror Zone Tracker <img style={{width: '36px'}} src={tzImg}
                                      alt="tz"/> {currentTZ.last_terror_zone_name_list}
            </span>
                                ),
                                tabContent: (
                                    <span>
           <div className="container">
      <a href="https://discord.gg/vQq4hXHrHA" style={{color: '#142d4c', textDecoration: 'none'}} rel="noreferrer"
         target="_blank">
  <button style={{
      display: 'flex',
      flexDirection: 'column', // Change the flex direction to column
      justifyContent: 'center',
      alignItems: 'center',
      padding: '10px 20px',
      border: 'none',
      backgroundColor: '#142d4c',
      color: 'white',
      fontSize: '16px',
      borderRadius: '5px',
      width: '100%',
      cursor: 'pointer',
      textAlign: 'center' // Ensure text is centered
  }}>
      Join Discord to subscribe to your favourite zones and get pinged before they spawn! <br/>
      Act: {currentTZ.last_terror_zone_act} <br/>
             Zones: {currentTZ.last_terror_zone_name_list}
  </button>
</a>
    </div>
         </span>
                                ),
                            },
                        ]}
                    />
                </div>
                <Footer whiteFont/>
            </div>
        </div>
    );
}
