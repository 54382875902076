import React from "react";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import {Redirect, useHistory} from "react-router-dom";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
// import People from "@material-ui/icons/People";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import toast from "react-hot-toast";

import config from "../../config/config.json"

import styles from "assets/jss/material-kit-react/views/loginPage.js";

import image from "assets/img/bgt.png";
import axios from "axios";
import d2rsIcon from "../../assets/img/d2rsicon.png";

const useStyles = makeStyles(styles);

export default function LoginPage(props) {
    const classes = useStyles();
    const {...rest} = props;

    const history = useHistory()

    async function login(event) {
        event.preventDefault()

        const name = document.getElementById('input.name').value
        const password = document.getElementById('input.password').value

        if (name === "" || password === "") {
            toast.error("name and password cannot be empty")
            return
        }
        await axios.post(config.backend_base_url + 'login', {
            "name": name,
            "password": password
        }).then(response => {
            console.log("Success ========>", response);
            if (name.toUpperCase() === "guest".toUpperCase()) {
                localStorage.setItem("guest_token", response.data)
            } else {
                localStorage.removeItem("guest_token")
            }
            localStorage.setItem("session_token", response.data["session_token"])
            localStorage.setItem("has_hardcore_access", response.data["has_hardcore_access"])
            if (response.data["role"] === "admin") {
                history.push("/supplier")
            } else {
                history.push("/due")
            }
        }).catch(error => {
            console.log("Error ========>", error);
            toast.error("Something went wrong. Please try again.")
        })
    }

    async function register(event) {
        event.preventDefault()

        toast.error("registering currently disabled")
        return

        // const name = document.getElementById('input.name').value
        // const password = document.getElementById('input.password').value
        //
        // if (name === "" || password === "") {
        //     toast.error("register: name and password cannot be empty")
        //     return
        // }
        // //todo: check password validity
        // await axios.post(config.backend_base_url + 'register', {
        //     "name": name,
        //     "password": password
        // }).then(response => {
        //     toast.loading("account creation successfull, you can login with your credentials now")
        //     localStorage.setItem("session_token", response.data["session_token"])
        //         history.push("/login")
        // }).catch(error => {
        //     console.log("Error ========>", error);
        //     toast.error("Something went wrong. Please try again.")
        // })
    }

    const loggedIn = localStorage.getItem("session_token")
    if (loggedIn) {
        return (<Redirect to="/due"/>)
    }
    return (
        <div>
            <Header
                color="dark"
                absolute
                brand="D2RS"
                rightLinks={<HeaderLinks/>}
                {...rest}
            />
            <div
                className={classes.pageHeader}
                style={{
                    backgroundImage: "url(" + image + ")",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundColor: "black",
                    backgroundPosition: "center",
                }}
            >
                <div className={classes.container}>
                    <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={4}>
                            <Card>
                                <form className={classes.form} id="loginForm" onSubmit={login}>
                                    <CardHeader color="primary" className={classes.cardHeader}>
                                        <img style={{ width: '128px' }} src={d2rsIcon} alt="d2rs" />
                                    </CardHeader>

                                    <CardBody>
                                        <CustomInput
                                            labelText="Username"
                                            id="name"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                type: "name",
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Email className={classes.inputIconsColor} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <CustomInput
                                            labelText="Password"
                                            id="password"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                type: "password",
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Icon className={classes.inputIconsColor}>
                                                            lock_outline
                                                        </Icon>
                                                    </InputAdornment>
                                                ),
                                                autoComplete: "off",
                                            }}
                                        />
                                    </CardBody>

                                    <CardFooter style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <Button style={{ marginBottom: '8px' }} color="primary" size="large" type="submit" fullWidth={true} form="loginForm">
                                            Login
                                        </Button>
                                        <Button color="primary" size="large" onClick={register} fullWidth={true} style={{ backgroundColor: "#385170" }}>
                                            Register
                                        </Button>
                                    </CardFooter>
                                </form>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
                <Footer whiteFont/>
            </div>
        </div>
    );
}
