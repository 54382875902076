import React, {useEffect, useRef, useState} from "react";
// @material-ui/core components
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import classNames from "classnames";
import Footer from "../../components/Footer/Footer";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/views/profilePage";
import {Redirect} from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import config from "../../config/config.json"

import {DataGrid} from "@mui/x-data-grid";
import Parallax from "../../components/Parallax/Parallax";
import NavPills from "../../components/NavPills/NavPills";
import {MenuItem, Select} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button";
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/icons/TextFields";

const useStyles = makeStyles(styles);
export default function SupplierPage(props) {
    const classes = useStyles();
    const {...rest} = props;
    const [loadRole, setLoadRole] = useState(true);
    const [userForumLink, setUserForumLink] = useState("");
    const [userPaypalEmail, setUserPaypalEmail] = useState("");
    const [muleGameAndCharAmount, setMuleGamesAndCharAmount] = useState({"free_chars": 0, "max_games": 0});
    const sessionToken = localStorage.getItem("session_token")
    if (!sessionToken) {
        return (<Redirect to="/login"/>)
    }

    if (loadRole) {
        setLoadRole(false)
    }

    const [settingsValues, setSettingsValues] = useState({
    });

    const [defaultGameName, setDefaultGameName] = useState("");
    const [defaultGamePassword, setDefaultGamePassword] = useState("");
    const [d2rGamesData, setD2rGamesData] = useState([]);

    const [muleGameName, setMuleGameName] = useState("");
    const [muleGamePassword, setMuleGamePassword] = useState("");

    async function getD2RGames() {
        let url = config.backend_base_url + 'mygames'
        await axios.get(url, {headers: {'session-token': sessionToken}}).then(response => {
            if (response.data != null && response.data.length > 0) {
                setD2rGamesData(response.data)
                //depending on the tab we load specific orders and set it as farmAccountsData
            } else {
                setD2rGamesData([])
            }
        }).catch(error => {
            console.log("Error ========>", error);
            toast.error(error.response.data["Message"])
        })
    }

    async function getFreeMuleCharAmount() {
        let url = config.backend_base_url + 'mls/mule/char/amount'
        await axios.get(url, {headers: {'session-token': sessionToken}}).then(response => {
            if (response.data != null) {
                setMuleGamesAndCharAmount(response.data)
            }
        }).catch(error => {
            console.log("Error ========>", error);
            toast.error(error.response.data["Message"])
        })
    }

    async function getAdminSettings() {
        let url = config.backend_base_url + 'admin/settings'
        await axios.get(url, {headers: {'session-token': sessionToken}}).then(response => {
            if (response.data != null) {
                setSettingsValues(response.data)

                if (response.data["forum_link"]) {
                    setUserForumLink(response.data["forum_link"])
                }
                if (response.data["paypal_email"]) {
                    setUserPaypalEmail(response.data["paypal_email"])
                }
                if (response.data["mule_game_name"]) {
                    setDefaultGameName(response.data["mule_game_name"])
                }
                if (response.data["mule_game_password"]) {
                    setDefaultGamePassword(response.data["mule_game_password"])
                }
            }
        }).catch(error => {
            console.log("Error ========>", error);
            toast.error(error.response.data["Message"])
        })
    }

    async function updateAdminSettings() {
        let checkFailed = false
        if (defaultGameName.length < 5) {
            toast.error("Your gamename must be at least 5 characters long.")
            checkFailed = true
        }
        if (defaultGamePassword.length < 5) {
            toast.error("Your password must be at least 5 characters long.")
            checkFailed = true
        }
        if (checkFailed) {
            return
        }

        let url = config.backend_base_url + 'admin/settings/update'
        await axios.post(url, {
            "paypal_email": userPaypalEmail,
            "forum_link": userForumLink,
            "mule_game_name": defaultGameName,
            "mule_game_password": defaultGamePassword,
        }, {headers: {'session-token': sessionToken}}).then(response => {
            if (response.data != null) {
                getAdminSettings()
                toast.success("Successfully updated Settings!")
            }
        }).catch(error => {
            console.log("Error ========>", error);
            toast.error(error.response.data["Message"])
        })
    }

    const [loadingData, setLoadingData] = useState(true);
    if (loadingData) {
        getAdminSettings();
        getD2RGames();
        getFreeMuleCharAmount();
        setLoadingData(false)
    }

    const d2rColumns = [
        {
            field: "name", headerName: "Game Name", flex: 4,
        },
        {
            field: "password", headerName: "Password", flex: 4,
        },
        {
            field: "node_name", headerName: "Node", flex: 4,
        },
        {
            field: "realm", headerName: "Realm", flex: 4,
        },
        {
            field: "fiat_only", headerName: "Fiat Only", flex: 1,
        },
        {
            field: "permanent", headerName: "Permanent", flex: 1,
        },
    ];

    async function openMuleGameForAdmin() {
        let checkFailed = false
        if (defaultGameName.length < 5) {
            toast.error("You must use a custom Gamename")
            checkFailed = true
        }
        if (defaultGamePassword.length < 5) {
            toast.error("You must use a custom Gamepassword")
            checkFailed = true
        }
        if (checkFailed) {
            return
        }

        let url = config.backend_base_url + 'game/open'
        await axios.post(url, {
            "fiat_only": fiatOnlyMuling,
            "realm": realmMuling,
            "game_name": muleGameName,
            "game_password": muleGamePassword
        }, {headers: {'session-token': sessionToken}}).then(response => {
            console.log("Success ========>", response);
            toast.success("Successfully opened Game.")
            getD2RGames()
        }).catch(error => {
                toast.error(error.response.data)
        })
    }

    async function closeMuleGames() {
        let url = config.backend_base_url + 'admin/mule/games/close'
        await axios.post(url, {}, {headers: {'session-token': sessionToken}}).then(response => {
            console.log("Success ========>", response);
            toast.success("Successfully closed Games.")
            getD2RGames()
        }).catch(error => {
            toast.error(error.response.data["Message"])
        })
    }

    let defaultMuleRealm = localStorage.getItem("default_realm")
    if (!defaultMuleRealm) {
        defaultMuleRealm = "europe"
    }
    const [realmMuling, setRealmMuling] = useState(defaultMuleRealm);
    const [fiatOnlyMuling, setFiatOnlyMuling] = useState(0);
    const [selectedMuleGameIDs, setSelectedMuleGameIDs] = useState([]);
    let sendCloseSelectedMuleGamesToRemove = function () {
        let uniqueIDs = selectedMuleGameIDs.filter((c, index) => {
            return selectedMuleGameIDs.indexOf(c) === index;
        });

        axios.post(config.backend_base_url + "admin/mule/game/close", {
            "game_ids": uniqueIDs,
        }, {headers: {'session-token': sessionToken}}).then(response => {
            console.log("Success ========>", response.data);
            toast.success("Successfully closed Games.")
            setSelectedMuleGameIDs([])
            getD2RGames()
        }).catch(error => {
            console.log("Error ========>", error);
            toast.error(error.response.data["Message"])
        })
    }

    const [headerHeight, setHeaderHeight] = useState(0);
    const headerRef = useRef(null);


    useEffect(() => {
        const updateHeaderHeight = () => {
            if (headerRef.current) {
                const headerComputedStyle = window.getComputedStyle(headerRef.current);
                const headerHeight = headerRef.current.offsetHeight;
                const headerPaddingTop = parseInt(headerComputedStyle.paddingTop, 10);
                const headerPaddingBottom = parseInt(headerComputedStyle.paddingBottom, 10);

                // Calculate total height including padding
                const totalHeight = headerHeight + (headerPaddingTop + headerPaddingBottom) *2;

                setHeaderHeight(totalHeight);
            }
        };

        updateHeaderHeight(); // Initial call to set height

        const intervalId = setInterval(() => {
            updateHeaderHeight();
        }, 1000); // Run every second

        // Stop updating after 10 seconds
        setTimeout(() => {
            clearInterval(intervalId);
        }, 10000);

        window.addEventListener("resize", updateHeaderHeight); // Update height on resize

        return () => {
            clearInterval(intervalId); // Cleanup interval on unmount
            window.removeEventListener("resize", updateHeaderHeight); // Cleanup listener on unmount
        };
    }, []);

    return (
        <div>
            <Header
                color="dark"
                brand="D2RS"
                rightLinks={<HeaderLinks/>}
                fixed
                ref={headerRef}
                {...rest}
            />
            <Parallax small filter headerHeight={headerHeight}
            />

            <div className={classNames(classes.main, classes.mainRaised)}>

                <div className={classes.container} style={{width: "80vw", position: "relative", maxWidth: "80vw", left: "60%", marginLeft: "-50vw", textAlign: "center"}}>
                    
                    <NavPills
                        queryKey={"t"}
                        alignCenter
                        onChange={function () {

                        }}
                        color="primary"
                        tabs={[
                            {
                                tabName:"games",
                                tabButton: "Mule Games",
                                tabContent: (
                                 <GridContainer spacing={1}>
                                     <GridItem xs={12} sm={12} md={12}>
                                         <b>{muleGameAndCharAmount.free_chars} service chars available,
                                             up to {muleGameAndCharAmount.max_games} games</b>
                                         <div style={{height: "100%", width: "100%"}}>
                                             <DataGrid
                                              autoHeight
                                              getRowWidth={() => 'auto'}
                                              columns={d2rColumns}
                                              rows={d2rGamesData}
                                              pageSize={10}
                                              rowsPerPageOptions={[10]}
                                              checkboxSelection
                                              onSelectionModelChange={(params) => {
                                                  let newSelection = []
                                                  params.forEach(function (item) {
                                                      let selItem = d2rGamesData.filter(function (selIte) {
                                                          return selIte.id === item
                                                      })[0]
                                                      newSelection.push(selItem.id)
                                                  });
                                                  setSelectedMuleGameIDs(newSelection)
                                              }}
                                             />
                                         </div>
                                     </GridItem>
                                     <GridItem xs={12} sm={12} md={12} xl={12} style={{paddingTop: 10}}>
                                         <Select
                                          value={fiatOnlyMuling}
                                          fullWidth={true}
                                          onChange={(params) => {
                                              setFiatOnlyMuling(params.target.value)
                                          }}
                                         >
                                             <MenuItem value={0}>Fiat + FG</MenuItem>
                                             <MenuItem value={1}>Fiat Only</MenuItem>
                                         </Select>
                                     </GridItem>
                                     <GridItem xs={12} sm={12} md={12} xl={12} style={{paddingTop: 10}}>
                                         <Select
                                          value={realmMuling}
                                          fullWidth={true}
                                          onChange={(params) => {
                                              setRealmMuling(params.target.value)
                                              localStorage.setItem("default_realm", params.target.value)
                                          }}
                                         >
                                             <MenuItem value={"americas"}>Americas</MenuItem>
                                             <MenuItem value={"europe"}>Europe</MenuItem>
                                         </Select>
                                     </GridItem>
                                     <GridItem xs={12} sm={12} md={12} xl={12} >
                                         <CustomInput
                                             labelText="Game Name"
                                             id="gameName"
                                             value={muleGameName}
                                             formControlProps={{
                                                 fullWidth: true,
                                             }}
                                             onChange={(params) => {
                                                 setMuleGameName(params.target.value)
                                             }}
                                             inputProps={{
                                                 type: "text",
                                                 endAdornment: (
                                                     <InputAdornment position="end">
                                                         <TextField/>
                                                     </InputAdornment>
                                                 ),
                                                 autoComplete: "off",
                                             }}
                                             required
                                         />
                                     </GridItem>
                                     <GridItem xs={12} sm={12} md={12} xl={12} >
                                         <CustomInput
                                             labelText="Game Password"
                                             id="gamePassword"
                                             value={muleGamePassword}
                                             formControlProps={{
                                                 fullWidth: true,
                                             }}
                                             onChange={(params) => {
                                                 setMuleGamePassword(params.target.value)
                                             }}
                                             inputProps={{
                                                 type: "text",
                                                 endAdornment: (
                                                     <InputAdornment position="end">
                                                         <TextField/>
                                                     </InputAdornment>
                                                 ),
                                                 autoComplete: "off",
                                             }}
                                             required
                                         />
                                     </GridItem>
                                     <GridItem xs={12} sm={12} md={12}>
                                         <FormControl id="openGameForm" fullWidth={true}>
                                             <Button color="primary"  type="submit"
                                                     onClick={openMuleGameForAdmin}>
                                                 Open Mule Game
                                             </Button>
                                         </FormControl>
                                     </GridItem>
                                     <GridItem xs={12} sm={12} md={12}>
                                         <FormControl id="closeMuleGamessForm" fullWidth={true}>
                                             <Button color="primary"  type="submit" disabled={selectedMuleGameIDs.length === 0}
                                                     onClick={sendCloseSelectedMuleGamesToRemove}>
                                                 Close Selected Mule Games
                                             </Button>
                                         </FormControl>
                                     </GridItem>
                                     <GridItem xs={12} sm={12} md={12}>
                                         <FormControl id="closeGamesForm" fullWidth={true}>
                                             <Button color="primary"  type="submit"
                                                     onClick={closeMuleGames}>
                                                 Close All Mule Games
                                             </Button>
                                         </FormControl>
                                     </GridItem>
                                 </GridContainer>
                                ),
                            },
                            {
                                tabName: "settings",
                                tabButton: "Settings",
                                tabContent: (
                                    <GridContainer justify="center">
                                        <GridItem xs={12} sm={12} md={12}>
                                            <CustomTabs
                                                headerColor="primary"
                                                tabs={[
                                                    {
                                                        tabName: "Mule Details",
                                                        tabContent: (
                                                            <div>
                                                                <GridContainer justify="center">
                                                                    <GridItem xs={12} sm={12} md={12}>
                                                                        <h2>Set your custom Mule Gamename and Password. <br/> Realm is determined by node location.</h2>
                                                                    </GridItem>
                                                                    <GridItem xs={12} sm={12} md={12}>
                                                                        <CustomInput
                                                                            labelText="Game Name"
                                                                            id="defaultGameName"
                                                                            value={defaultGameName}
                                                                            inputProps={{
                                                                                type: "text",
                                                                            }}
                                                                            formControlProps={{
                                                                                fullWidth: true,
                                                                            }}
                                                                            onChange={(params) => {
                                                                                settingsValues["mule_game_name"] = params.target.value
                                                                                setSettingsValues(settingsValues)
                                                                                setDefaultGameName(params.target.value)
                                                                            }}
                                                                        />
                                                                    </GridItem>
                                                                    <GridItem xs={12} sm={12} md={12}>
                                                                        <CustomInput
                                                                            labelText="Game Password"
                                                                            id="defaultGamePassword"
                                                                            value={defaultGamePassword}
                                                                            inputProps={{
                                                                                type: "text",
                                                                            }}
                                                                            formControlProps={{
                                                                                fullWidth: true,
                                                                            }}
                                                                            onChange={(params) => {
                                                                                settingsValues["mule_game_password"] = params.target.value
                                                                                setSettingsValues(settingsValues)
                                                                                setDefaultGamePassword(params.target.value)
                                                                            }}
                                                                        />
                                                                    </GridItem>
                                                                    <GridItem xs={12} sm={12} md={12}>
                                                                        <br/>
                                                                        <br/>
                                                                        <Button fullWidth={true} color={"primary"} size={"lg"}
                                                                                onClick={updateAdminSettings}
                                                                        >
                                                                            Update Settings
                                                                        </Button>
                                                                    </GridItem>
                                                                </GridContainer>
                                                            </div>
                                                        ),
                                                    },
                                                    {
                                                        tabName: "Payment Details",
                                                        tabContent: (
                                                            <div>
                                                                <GridContainer justify="center">
                                                                    <GridItem xs={12} sm={12} md={12}>
                                                                        <h2>Set your payment details. You will be paid or you have to pay depending on your due.</h2>
                                                                    </GridItem>
                                                                    <GridItem xs={12} sm={12} md={12}>
                                                                        <CustomInput
                                                                            labelText="Paypal Email"
                                                                            id="paypalEmail"
                                                                            value={userPaypalEmail}
                                                                            onChange={(params) => {
                                                                                setUserPaypalEmail(params.target.value)
                                                                            }}
                                                                            inputProps={{
                                                                                type: "text",
                                                                            }}
                                                                            formControlProps={{
                                                                                fullWidth: true,
                                                                            }}
                                                                        />
                                                                        <br/>
                                                                        <CustomInput
                                                                            labelText="Forum Link"
                                                                            id="forumLink"
                                                                            value={userForumLink}
                                                                            onChange={(params) => {
                                                                                setUserForumLink(params.target.value)
                                                                            }}
                                                                            inputProps={{
                                                                                type: "text",
                                                                            }}
                                                                            formControlProps={{
                                                                                fullWidth: true,
                                                                            }}
                                                                        />
                                                                    </GridItem>
                                                                    <GridItem xs={12} sm={12} md={12}>
                                                                        <br/>
                                                                        <br/>
                                                                        <Button fullWidth={true} color={"primary"} size={"lg"}
                                                                                onClick={updateAdminSettings}
                                                                        >
                                                                            Update Settings
                                                                        </Button>
                                                                    </GridItem>
                                                                </GridContainer>
                                                            </div>
                                                        ),
                                                    },
                                                ]}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                ),
                            },
                        ]}
                    />
                </div>
                <Footer/>
            </div>
        </div>
    );
}