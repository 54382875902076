/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";
import {Link} from "react-router-dom";
import d2rsIcon from "../../assets/img/d2rsicon.png";
import discordImg from "../../assets/img/discord.png";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a href="https://discord.gg/vQq4hXHrHA" style={{color: '#142d4c', textDecoration: 'none'}}
                 rel="noreferrer"
                 target="_blank">
                <img style={{width: '86px'}} src={discordImg} alt="discord"/>
              </a>
            </ListItem>{"     "}
          </List>
        </div>
        <div className={classes.right}>
          &copy; {1900 + new Date().getYear()} {" "}
          <a
              href="https://www.d2rs.io"
              className={aClasses}
              target="_blank"
          >
            <img style={{width: '64px' }} src={d2rsIcon} alt="d2rs" />
          </a>{" "}
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
};
